import { GlobalToken } from 'antd';

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  // 普通带边框
  bgNormalBorder: (token: GlobalToken) => {
    return {
      color: token.colorText,
      border: `1px solid ${token.colorBorder}`,
      backgroundColor: token.colorBgContainer,
    };
  },
  // 普通无边框
  bgElevated: (token: GlobalToken) => {
    return {
      color: token.colorText,
      border: `1px solid ${token.colorBgElevated}`,
      backgroundColor: token.colorBgElevated,
    };
  },
  // 普通带边框
  bgElevatedBorder: (token: GlobalToken) => {
    return {
      color: token.colorText,
      border: `1px solid ${token.colorBorder}`,
      backgroundColor: token.colorBgElevated,
    };
  },
  // 主题色无边框
  bgPrimary: (token: GlobalToken) => {
    return {
      color: token.colorText,
      border: `1px solid ${token.colorPrimary}`,
      backgroundColor: token.colorPrimary,
    };
  },
  // 主题色带边框
  bgPrimaryBorder: (token: GlobalToken) => {
    return {
      color: token.colorPrimary,
      border: `1px solid ${token.colorPrimary}`,
      backgroundColor: token.colorPrimaryBg,
    };
  },
  // 成功色带边框
  bgSuccessBorder: (token: GlobalToken) => {
    return {
      color: token.colorSuccess,
      border: `1px solid ${token.colorSuccess}`,
      backgroundColor: token.colorSuccessBg,
    };
  },
  // 错误色带边框
  bgErrorBorder: (token: GlobalToken) => {
    return {
      color: token.colorError,
      border: `1px solid ${token.colorError}`,
      backgroundColor: token.colorErrorBg,
    };
  },
  // 警告色带边框
  bgWarningBorder: (token: GlobalToken) => {
    return {
      color: token.colorWarning,
      border: `1px solid ${token.colorWarning}`,
      backgroundColor: token.colorWarningBg,
    };
  },
  // 失败色带边框
  bgFailBorder: (token: GlobalToken) => {
    return {
      color: (token as any).colorFail,
      border: `1px solid ${(token as any).colorFail}`,
      backgroundColor: (token as any).colorFailBg,
    };
  },
  // 默认带边框
  bgDefaultBorder: (token: GlobalToken) => {
    return {
      color: (token as any).colorTextTertiary,
      border: `1px solid ${(token as any).colorTextTertiary}`,
      backgroundColor: (token as any).colorBgTextHover,
    };
  },
  // 链接色带边框
  bgLinkBorder: (token: GlobalToken) => {
    return {
      color: token.colorLink,
      border: `1px solid ${token.colorLink}`,
      backgroundColor: token.colorBgContainer,
    };
  },
};
