import { axios, axios2 } from '../utils/axios';
import { PlatformType, YoutubeVideoType } from '@/stores/CommonStore/types';

export interface AwsConfigType {
  accessKeyId: string;
  accessKeySecret: string;
  endpoint: string;
  expire: string;
  region: string;
  stsToken: string;
}

export interface IPlatformsResponse {
  list: PlatformType[];
}

export interface IYoutubeResponse {
  etag: string;
  kind: string;
  items: YoutubeVideoType[];
}

export interface IOssUrlResponse {
  id: number;
  method: string;
  url: string;
}

const commonServices = {
  getOssPolicy: () => axios.get('/interface/v1/oss/policy'),
  getAwsConfig: () => axios.get<AwsConfigType, AwsConfigType>('/interface/v1/s3/sts'),
  getPlatforms: () => axios2.get<IPlatformsResponse, IPlatformsResponse>('studio/v1/platforms'),
  getYoutubeVideo: (params: any) =>
    axios2.get<IYoutubeResponse, IYoutubeResponse>('https://www.googleapis.com/youtube/v3/videos', {
      params,
      baseURL: '',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }),
};

export default commonServices;
