import i18n, { tf } from '@/i18n/config';
import { CountryType } from '@/stores/CommonStore/types';
import Cookie from 'js-cookie';
import dayjs from 'dayjs';

const StringUtils = {
  /**
   * 转译正则表达式中的特殊字符
   * @params value 需要转译的目标值
   * @returns value 转译后的值
   */
  addEscape: (value: string) => {
    let arr = [
      '(',
      '[',
      '{',
      '/',
      '^',
      '$',
      '¦',
      '}',
      ']',
      ')',
      '?',
      '*',
      '+',
      '.',
      "'",
      '"',
      '\\',
    ];
    for (let i = 0; i < arr.length; i++) {
      if (value) {
        if (value.indexOf(arr[i]) > -1) {
          const reg = (str: any) => str?.replace(/[[\\\]/{}()*'"¦+?.\\\\^$|]/g, '\\$&');
          value = reg(value);
        }
      }
    }
    return value;
  },

  /**
   * 关键字变色
   * @params content 内容
   * @params keyword 关键词
   * @params tagName 标签名
   */
  warpTag: (content: any, keyword: any, tagName: any, color: string) => {
    if (content === 'No results') {
      return content;
    }
    const a = content?.toLowerCase();
    const b = keyword?.toLowerCase();

    const indexOf = a?.indexOf(b);
    const c = indexOf > -1 ? content?.substr(indexOf, keyword.length) : '';
    const val = `<${tagName} style="color:${color};">${c}</${tagName}>`;
    // const regS = new RegExp(keyword, "gi");
    const regS = new RegExp(StringUtils.addEscape(keyword), 'gi'); // 转译keyword中的特殊字符
    return content?.replace(regS, val);
  },

  getCurrentLanguage: () => {
    return i18n.language === 'zh' ? 'ZH_CN' : i18n.language === 'ko' ? 'KO_KR' : 'OTHER';
  },

  // 展示时间 00:00
  displayDuration: (duration: number) => {
    const hours = parseInt((duration / 3600).toString(), 10)
      .toString()
      .padStart(2, '0');
    const minutes = parseInt(((duration % 3600) / 60).toString(), 10)
      .toString()
      .padStart(2, '0');
    const seconds = parseInt((duration % 60).toString(), 10)
      .toString()
      .padStart(2, '0');
    return `${duration > 3600 ? `${hours}:` : ''}${minutes}:${seconds}`;
  },

  /**
   * 将数字转为以万为单位
   * @params value 需要转换的数字或字符串
   * @params decimal 非万为单位时需要保留几位小数,默认保留两位
   */
  handleThousand: (value: any, float: number = 1) => {
    let num = Number(value);
    if (num === 0) {
      return num + '';
    } else if (num >= 1 && num < 10000) {
      // return num.toFixed(float) + '';
      return num + '';
    } else {
      // 保留一位小数，不进行四舍五入
      value = (num / 10000).toString();
      let index = value.indexOf('.');
      if (index !== -1) {
        value = value.substring(0, float + index + 1);
      } else {
        value = value.substring(0);
      }
      return parseFloat(value).toFixed(float) + 'w';
    }
  },

  /**
   * 转为百分数并保留两位小数
   * @params value 需要处理的值
   * @return percent 保留两位小数的百分数
   */
  toPercent: (value: number | string | undefined) => {
    if (value === undefined) value = 0;
    if (value === -1) return (value = '-');
    let percent = (Number(value) * 100).toFixed(2);
    percent += '%';
    return percent;
  },

  /**
   * 打开新的页面
   * @params url 跳转的目标路径
   */
  handleOpenNewPage: (url: string) => {
    const w = window.open('about:blank');
    if (url) {
      w!.location.href = url;
    } else {
      w!.location.href = '404';
    }
  },

  /**
   * 处理手机号  传入 86-18888888888 返回 +8618888888888
   */
  handleChannelTalkPhone: (phoneNumber?: string) => {
    if (!phoneNumber) {
      return '';
    }
    const countryCode = phoneNumber.split('-')[0];
    const phoneNum = phoneNumber.split('-')[1];
    const phone = '+' + countryCode + phoneNum;
    return phone;
  },

  /**
   * 展示手机号  传入 86-18888888888 展示 188*****888
   */
  displayPhone: (phoneNumber?: string) => {
    if (!phoneNumber) {
      return '-';
    }
    const phoneNum = phoneNumber.split('-')[1];
    const phone =
      phoneNum.slice(0, 3) +
      '******************'.slice(0, phoneNum.length - 6) +
      phoneNum.slice(phoneNum.length - 3, phoneNum.length);
    return phone;
  },

  /**
   * 展示手机号  传入 86-18888888888 展示 +86 18888888888
   */
  displayPhoneNumber: (phoneNumber?: string) => {
    if (!phoneNumber) {
      return '';
    }
    const countryCode = phoneNumber.split('-')[0];
    const phoneNum = phoneNumber.split('-')[1];
    const phone = '+' + countryCode + ' ' + phoneNum;
    return phone;
  },

  /**
   * 展示邮箱  传入 piaodayi@adoba.net 展示 pia*****@adoba.net
   */
  displayEmail: (emailString?: string) => {
    if (!emailString) {
      return '-';
    }
    const emailStr = emailString.split('@')[0];
    const email = emailStr.slice(0, 3) + '***@' + emailString.split('@')[1];
    return email;
  },

  // 点赞数量展示
  displayCount: (count?: number | string) => {
    let countNum = count || 0;
    if (!countNum) {
      return '-';
    } else {
      countNum = Number(countNum);
    }
    let countStr = countNum.toString();
    if (countNum > 999999999) {
      countStr = (countNum / 1000000000).toFixed(1) + 'B';
    } else if (countNum > 999999) {
      countStr = (countNum / 1000000).toFixed(1) + 'M';
    } else if (countNum > 999) {
      countStr = (countNum / 1000).toFixed(1) + 'K';
    }
    return countStr;
  },

  /**
   * 根据合同状态显示‘运营中’‘解约’‘休眠’及对应颜色
   * @params value 合同的类型：1,2,3
   * @params type 需要展示文字或者颜色
   */
  handleContractStatus: (value: number, type: 'text' | 'color') => {
    let status: string = '';
    let view: string = '#ccc';

    switch (value) {
      case 0:
        status = '运营中';
        view = '#1db100';
        break;
      case 1:
        status = '解约';
        view = '#929292';
        break;
      case 2:
        status = '未签约';
        view = '#ccc';
        break;
      case 3:
        status = '未运营';
        view = '#feae00';
        break;
      default:
        status = '-';
        break;
    }
    if (type === 'text') return status;
    if (type === 'color') return view;
  },

  /**
   * 处理日期显示格式
   * @params date 需要处理的日期
   * @return dateShow 处理后需要展示的日期格式
   */
  handleDateShow: (date?: string) => {
    let dateShow = '-';
    if (date && date !== '0001-01-01T00:00:00Z') {
      dateShow = dayjs(date).format('YYYY-MM-DD');
    } else {
      dateShow = '-';
    }
    return dateShow;
  },

  /**
   * 处理文件显示格式
   * @params size 文件大小
   * @return formart 处理后需要展示的文件大小
   */
  handleFileSize: (size: number) => {
    let fileSize = '';
    if (size >= 1024 * 1024 * 1024) {
      fileSize = (size / 1024 / 1024 / 1024).toFixed(2) + 'GB';
    } else if (size >= 1024 * 1024) {
      fileSize = (size / 1024 / 1024).toFixed(2) + 'MB';
    } else if (size >= 1024) {
      fileSize = (size / 1024).toFixed(2) + 'KB';
    } else if (size < 1024) {
      fileSize = size + 'bit';
    }
    return fileSize;
  },

  /**
   * 处理文件显示格式
   * @params size 文件大小
   * @return formart 处理后需要展示的文件大小
   */
  handleUploadFileSize: (size = 0, target: string) => {
    let fileSize = '';
    if (target.includes('bit')) {
      fileSize = size + 'bit';
    } else if (target.includes('KB')) {
      fileSize = (size / 1024).toFixed(2) + 'KB';
    } else {
      if (size >= 1024 * 1024 * 1024) {
        fileSize = (size / 1024 / 1024 / 1024).toFixed(2) + 'GB';
      } else {
        fileSize = (size / 1024 / 1024).toFixed(2) + 'MB';
      }
    }
    return fileSize;
  },

  /**
   * 处理选择国籍的翻译
   * @param language 当前选择的语言
   * @param item CountryType
   * @returns 根据当前选择的语言返回对应的语言
   */
  handleCountryName: (language: string = 'zh', item: CountryType) => {
    let nameLang: string = item?.zh;
    switch (language) {
      case 'zh':
        nameLang = item?.zh;
        break;
      case 'en':
        nameLang = item?.en;
        break;
      case 'ko':
        nameLang = item?.ko;
        break;
      default:
        nameLang = item?.zh;
        break;
    }
    return nameLang;
  },

  /**
   * 处理合同类型的显示
   * @param type 返回的合同类型
   * @returns 需要展示的合同类型名称
   */
  handleContractTypeShow: (type?: number) => {
    let typeName: string = '-';
    switch (type) {
      case 1:
        typeName = tf('account.contract_type_personal'); // 个人
        break;
      case 2:
        typeName = tf('account.contract_type_enterprise'); // 企业
        break;
      case 3:
        typeName = tf('account.contract_type_mcn'); // MCN
        break;
      default:
        typeName = '-';
        break;
    }
    return typeName;
  },

  /**
   * 获取当前项目环境
   * @returns env 'dev' | 'prod'
   */
  getEnvironment: () => {
    const currentHost: string = window.location.host;
    switch (currentHost) {
      case 'www.adobaro.com': // 生产环境
      case 'v2.adobaro.com': // 生产环境
      case 'adobaro.com': // 生产环境
      case 'allen.adoba.tv': // 临时 生产环境
        return 'prod';
      default:
        return 'dev';
    }
  },

  /**
   * 清除cookies
   */
  removeCookies: () => {
    Cookie.remove('token');
    Cookie.remove('refresh_token');
    Cookie.remove('ch-veil-id', { path: '', domain: 'adobaro.com' });
    Cookie.remove('ch-veil-id', { path: '', domain: 'www.adobaro.com' });
    Cookie.remove('ch-session-99516', { path: '', domain: 'adobaro.com' });
    Cookie.remove('ch-session-99516', { path: '', domain: 'www.adobaro.com' });
  },

  /**
   * 素材管理文件大小
   * @param value
   * @returns
   */
  handleMaterialFileSizeUnit: (value: string | number) => {
    let fileSize = '';
    let size = Number(value);

    if (size === 0) {
      fileSize = '0.00GB';
    } else if (size >= 1024 * 1024 * 1024) {
      fileSize = (size / 1024 / 1024 / 1024).toFixed(2) + 'GB';
    } else if (size >= 1024 * 1024) {
      fileSize = (size / 1024 / 1024).toFixed(2) + 'MB';
    } else if (size >= 1024) {
      fileSize = (size / 1024).toFixed(2) + 'KB';
    } else if (size < 1024) {
      fileSize = size + 'bit';
    }

    return fileSize;
  },

  /**
   * 根据当前语言打开 各个平台的协议页面
   * @param type 类别名称
   * @param platformId 平台id
   * @returns 需要打开的目标链接地址
   */
  handlePlatformProtocol: (type: 'user' | 'privacy', platformId: number) => {
    const list = [
      {
        name: 'platform_hao_kan',
        platformId: 1,
        user: {
          zh: 'https://haokan.baidu.com/videoui/page/about#agreement',
          en: '',
          ko: '',
        },
        privacy: {
          zh: 'https://haokan.baidu.com/videoui/page/about#privacy',
          en: '',
          ko: '',
        },
      },
      {
        name: 'platform_iqiyi',
        platformId: 3,
        user: {
          zh: 'https://www.iqiyi.com/user/register/protocol.html',
          en: '',
          ko: '',
        },
        privacy: {
          zh: 'https://privacy.iqiyi.com/policies',
          en: '',
          ko: '',
        },
      },
      {
        name: 'platform_bilibili',
        platformId: 4,
        user: {
          zh: 'https://www.bilibili.com/protocal/licence.html',
          en: 'https://vr0omf0e2s.feishu.cn/docx/XFpIdve6MoGHhCxUWCmctXIEnJt',
          ko: 'https://vr0omf0e2s.feishu.cn/docx/IOBQd65rboyrNCxxZqjcw7oanWg',
        },
        privacy: {
          zh: 'https://www.bilibili.com/blackboard/privacy-pc.html',
          en: 'https://vr0omf0e2s.feishu.cn/docx/T8NndtEkDoT01txGcK7ceygPnZc',
          ko: 'https://vr0omf0e2s.feishu.cn/docx/DMsXdudVkoohBtxp6mZcnkd7nIh',
        },
      },
      {
        name: 'platform_xi_gua',
        platformId: 5,
        user: {
          zh: 'https://www.ixigua.com/user_agreement/',
          en: '',
          ko: '',
        },
        privacy: {
          zh: 'https://www.ixigua.com/privacy_policy/',
          en: '',
          ko: '',
        },
      },
      {
        name: 'platform_wei_bo',
        platformId: 6,
        user: {
          zh: 'https://weibo.com/signup/v5/protocol',
          en: '',
          ko: '',
        },
        privacy: {
          zh: 'https://weibo.com/signup/v5/privacy',
          en: '',
          ko: '',
        },
      },
      {
        name: 'platform_red_book',
        platformId: 9,
        user: {
          zh: 'https://agree.xiaohongshu.com/h5/terms/ZXXY20220331001/-1',
          en: '',
          ko: '',
        },
        privacy: {
          zh: 'https://agree.xiaohongshu.com/h5/terms/ZXXY20220509001/-1',
          en: '',
          ko: '',
        },
      },
      {
        name: 'platform_kuai_shou',
        platformId: 10,
        user: {
          zh: 'https://www.kuaishou.com/about/policy?tab=protocol',
          en: '',
          ko: '',
        },
        privacy: {
          zh: 'https://www.kuaishou.com/about/policy?tab=privacy',
          en: '',
          ko: '',
        },
      },
      {
        name: 'platform_qq_world',
        platformId: 16,
        user: {
          zh: 'https://rule.tencent.com/rule/preview/a880964c-66e7-4556-8a39-827f92b2b355',
          en: '',
          ko: '',
        },
        privacy: {
          zh: 'https://privacy.qq.com/document/preview/33e5320e241645329f9e7cb2a81ec68e',
          en: '',
          ko: '',
        },
      },
    ];

    const platform = list.find((item) => item.platformId === platformId);
    if (platform) {
      const language = i18n.language === 'zh' ? 'zh' : i18n.language === 'ko' ? 'ko' : 'en';
      const docLink =
        platform[type][language] !== '' ? platform[type][language] : platform[type]['zh'];

      return docLink;
    }
    return '';
  },

  /**
   * 根据当前语言打开 adoba协议和帮助中心页面
   * @param type 类别名称
   * @returns 需要打开的目标链接地址
   */
  handleOpenProtocolOrHelp: (
    type:
      | 'user' // 用户协议
      | 'privacy' // 隱私政策
      | 'marketing' // 营销通知
      | 'operating' // 平台运营协议
      | 'coin' // 金币规则
      | 'home' // 帮助中心首页
      | 'channel' // channel
      | 'platform' // 是否为平台
      | 'bilibili' // bilibili
      | 'xiaohongshu', // xiaohongshu
    platformId?: number
  ) => {
    // 帮助中心网址列表
    const helpCenterList = [
      {
        type: 'user',
        name: 'protocol_user',
        en: 'https://vr0omf0e2s.feishu.cn/docx/U9dldBmzRoLiwaxh567cLuXbnSc',
        ko: 'https://vr0omf0e2s.feishu.cn/docx/YUyYdCUMfoevepxxCqRcnbcHnUh',
        zh: 'https://vr0omf0e2s.feishu.cn/docx/Dc0Hd4DZzoSHoqxAuZbczDDEn6d',
      },
      {
        type: 'privacy',
        name: 'protocol_privacy',
        en: 'https://vr0omf0e2s.feishu.cn/docx/XSTjdZknco64bpxPHlfcSOUIn7e',
        ko: 'https://vr0omf0e2s.feishu.cn/docx/Sg20dGvrXoOIFTxFkrmcVRbanUf',
        zh: 'https://vr0omf0e2s.feishu.cn/docx/RmfLdb13IoACFkxUERJc1i7bnHc',
      },
      {
        type: 'marketing',
        name: 'protocol_marketing',
        en: 'https://vr0omf0e2s.feishu.cn/docx/FQb7dUMuJoMLfgx0eDmcxm50nHf',
        ko: 'https://vr0omf0e2s.feishu.cn/docx/Uk6WdHgEMoLWXoxk1utcVv4cnKd',
        zh: 'https://vr0omf0e2s.feishu.cn/docx/BYQndeDGeo7b9dxAyf2c1OO7nsc',
      },
      {
        type: 'operating',
        name: '平台运营协议', // 平台运营协议
        zh: 'https://vr0omf0e2s.feishu.cn/docx/LpLPddHnNopp4fxJhbJc3emxnmW',
        ko: 'https://vr0omf0e2s.feishu.cn/docx/T61Ad6upEoijBMxHEGLcrdu3n7d',
        en: 'https://vr0omf0e2s.feishu.cn/docx/XxA3dhr1xoRhNoxPgBhcSXo5nhg',
      },
      {
        type: 'home',
        name: '帮助中心首页',
        en: 'https://vr0omf0e2s.feishu.cn/docx/VNCtdCkkJo40BwxftZVcZuLwnBb#part-AUcTdccjCoLAzix5zuDcBz8Jncf',
        ko: 'https://vr0omf0e2s.feishu.cn/docx/VLF2dTAoVoUSFQxOqTJcKT4innb#part-ZtBLdQA7ioICtexUG19cWqHAnKb',
        zh: 'https://vr0omf0e2s.feishu.cn/docx/RrnbdD31YoaXfVxC71bc1jBSnZd#part-PhCIdqjDvoPzXIxdyZmcsGuunxg',
      },
      {
        type: 'coin',
        name: '金币规则',
        en: 'https://vr0omf0e2s.feishu.cn/docx/VNCtdCkkJo40BwxftZVcZuLwnBb#B09kdCnhIoN4ZRxayffcP8SsnAh',
        ko: 'https://vr0omf0e2s.feishu.cn/docx/VLF2dTAoVoUSFQxOqTJcKT4innb#ERSMdDJvVoJbU4xLNF0cZerQnad',
        zh: 'https://vr0omf0e2s.feishu.cn/docx/RrnbdD31YoaXfVxC71bc1jBSnZd#doxcnGfhibZAP7Z4IFYkhuLtUxd',
      },
      {
        type: 'channel',
        name: 'channel ID',
        en: 'https://vr0omf0e2s.feishu.cn/docx/VNCtdCkkJo40BwxftZVcZuLwnBb#Jls0dWGRBoNfQdx7YA8cu6YCnlg',
        ko: 'https://vr0omf0e2s.feishu.cn/docx/VLF2dTAoVoUSFQxOqTJcKT4innb#ORHGdOreMoGJpgxPFV8cKydMnBO',
        zh: 'https://vr0omf0e2s.feishu.cn/docx/RrnbdD31YoaXfVxC71bc1jBSnZd#doxcnDXFw783sVKlpfFgsqzdS1d',
      },
      {
        type: 'platform',
        platformId: 4,
        name: 'B站',
        en: 'https://vr0omf0e2s.feishu.cn/docx/VNCtdCkkJo40BwxftZVcZuLwnBb#IPHmdJDteohEPLxuEFWcUUeVnZe',
        ko: 'https://vr0omf0e2s.feishu.cn/docx/VLF2dTAoVoUSFQxOqTJcKT4innb#D4ISdSXhBout8uxG6XTcv8JenDx',
        zh: 'https://vr0omf0e2s.feishu.cn/docx/RrnbdD31YoaXfVxC71bc1jBSnZd#doxcn3TTuN6I4RTX42gWkVOMVOg',
      },
      {
        type: 'platform',
        platformId: 9,
        name: '小红书',
        en: 'https://vr0omf0e2s.feishu.cn/docx/VNCtdCkkJo40BwxftZVcZuLwnBb#J7jud25wkoKq3Cxcw8Tc2bBtntd',
        ko: 'https://vr0omf0e2s.feishu.cn/docx/VLF2dTAoVoUSFQxOqTJcKT4innb#K5N5dJdvQoD3tjxxTpMcKC1lnzd',
        zh: 'https://vr0omf0e2s.feishu.cn/docx/RrnbdD31YoaXfVxC71bc1jBSnZd#doxcnq63c3WVSObnfgHlyXY3Vad',
      },
      {
        type: 'platform',
        platformId: 5,
        name: '西瓜',
        en: 'https://vr0omf0e2s.feishu.cn/docx/VNCtdCkkJo40BwxftZVcZuLwnBb#FT7XdQG4uogMBexBkArcA0IPn1e',
        ko: 'https://vr0omf0e2s.feishu.cn/docx/VLF2dTAoVoUSFQxOqTJcKT4innb#RfeKdDCRgoI0lOxA91lc9NeBnYc',
        zh: 'https://vr0omf0e2s.feishu.cn/docx/RrnbdD31YoaXfVxC71bc1jBSnZd#doxcnbehSxOTTYK7DtZYcTjIzVh',
      },
      {
        type: 'platform',
        platformId: 1,
        name: '好看',
        en: 'https://vr0omf0e2s.feishu.cn/docx/VNCtdCkkJo40BwxftZVcZuLwnBb#UyKndvnT0or4JYxxHYEckBcYn4b',
        ko: 'https://vr0omf0e2s.feishu.cn/docx/VLF2dTAoVoUSFQxOqTJcKT4innb#BqPUduaekoOFRkx3CjicG776nEf',
        zh: 'https://vr0omf0e2s.feishu.cn/docx/RrnbdD31YoaXfVxC71bc1jBSnZd#doxcnsQXDfseAxurN7jM9khLt2J',
      },
      {
        type: 'platform',
        platformId: 3,
        name: '爱奇艺',
        en: 'https://vr0omf0e2s.feishu.cn/docx/VNCtdCkkJo40BwxftZVcZuLwnBb#YQkXd7UEnoU5PrxrrjwcmkZtnRh',
        ko: 'https://vr0omf0e2s.feishu.cn/docx/VLF2dTAoVoUSFQxOqTJcKT4innb#VhoOdGXtaog42NxSwEJcPpv3nDd',
        zh: 'https://vr0omf0e2s.feishu.cn/docx/RrnbdD31YoaXfVxC71bc1jBSnZd#doxcn3KWzrYVu8e5nfPwn16Sqtg',
      },
      {
        type: 'platform',
        platformId: 10,
        name: '快手',
        en: 'https://vr0omf0e2s.feishu.cn/docx/VNCtdCkkJo40BwxftZVcZuLwnBb#ZWdTdIJYHoVow9xapvycv5IVnOh',
        ko: 'https://vr0omf0e2s.feishu.cn/docx/VLF2dTAoVoUSFQxOqTJcKT4innb#LUPhdEMg1oYZIBxaYmhcaeQyn8g',
        zh: 'https://vr0omf0e2s.feishu.cn/docx/RrnbdD31YoaXfVxC71bc1jBSnZd#doxcnTbZ74wRA4fJ5oaidVNa23g',
      },
      {
        type: 'platform',
        platformId: 6,
        name: '微博',
        en: 'https://vr0omf0e2s.feishu.cn/docx/VNCtdCkkJo40BwxftZVcZuLwnBb#VJWSdN053oM7W8x6GM7c9RajnfE',
        ko: 'https://vr0omf0e2s.feishu.cn/docx/VLF2dTAoVoUSFQxOqTJcKT4innb#PbeMd3xJPoiM0oxeW8kcaRzdnte',
        zh: 'https://vr0omf0e2s.feishu.cn/docx/RrnbdD31YoaXfVxC71bc1jBSnZd#doxcn7vTTZPodi6xg1dyI6gNN5c',
      },
      {
        type: 'platform',
        platformId: 16,
        name: 'QQ小世界',
        en: 'https://vr0omf0e2s.feishu.cn/docx/VNCtdCkkJo40BwxftZVcZuLwnBb#FkOodCzsLo0Rg7x3sGwcC4RInRP',
        ko: 'https://vr0omf0e2s.feishu.cn/docx/VLF2dTAoVoUSFQxOqTJcKT4innb#JvVAd8loxo3Jc4xioKoclz43nse',
        zh: 'https://vr0omf0e2s.feishu.cn/docx/RrnbdD31YoaXfVxC71bc1jBSnZd#doxcnK7BGwPKGUq26dp1KGs5zgb',
      },
    ];

    const language = i18n.language === 'zh' ? 'zh' : i18n.language === 'ko' ? 'ko' : 'en';
    const docLink =
      helpCenterList.find((item) =>
        item.type === 'platform' ? item.platformId === platformId : item.type === type
      )?.[language] || '';

    return docLink;
  },
};

export default StringUtils;
