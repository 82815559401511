import React, { Suspense, useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { useRoutes, useNavigate } from 'react-router-dom';
import routes from './routers';
import Loading from '@/components/Loading/loading';
// import ReleaseCountdown from './pages/ReleaseCountdown';
// import { roadDarkAlgorithm, roadLightAlgorithm } from './theme';
import { inject, observer } from 'mobx-react';
import { isBrowser } from 'react-device-detect';
import CommonStore from '@/stores/CommonStore';
import LanguageStore from '@/stores/LanguageStore';
import { roadDarkAlgorithm, roadLightAlgorithm } from './styles/theme';
// import dayjs from 'dayjs';
import zhCN from 'antd/locale/zh_CN';
import enUS from 'antd/locale/en_US';
import koKR from 'antd/locale/ko_KR';
import { Locale } from 'antd/lib/locale';
import './i18n/config'; // 引用配置文件
import './App.less';
// import StringUtils from './utils/StringUtils';
import i18n from './i18n/config';

// let CountdownTimer: any;

interface IProps {
  common?: CommonStore;
  language?: LanguageStore;
}

const App: React.FC<IProps> = (props) => {
  const { common, language } = props;

  const routing = useRoutes(routes);
  const navigate = useNavigate();

  const [locale, setLocale] = useState<Locale>();
  // const [showCountdown, setShowCountdown] = useState<boolean>(false);
  // const { defaultAlgorithm, darkAlgorithm } = theme;

  useEffect(() => {
    // 初始化友盟SDK
    common?.uMengInit();

    // 判断用户端设备 非PC端浏览器打开项目时跳转到/device-reminder
    !isBrowser && navigate('/device-reminder');
  }, []);

  useEffect(() => {
    const lang = language?.language || i18n.language;
    if (lang === 'en') {
      setLocale(enUS);
    } else if (lang === 'zh') {
      setLocale(zhCN);
    } else {
      setLocale(koKR);
    }
  }, [language?.language]);

  // 上线之前显示ReleaseCountdown页面, 2023-06-20 19:00:00之前和 2023-06-21 22:00:00之后, 生产环境正常显示项目
  // useEffect(() => {
  //   const deadline = '2023-06-21 22:00:00';
  //   if (dayjs().isBefore(dayjs(deadline)) && StringUtils.getEnvironment() === 'prod') {
  //     setShowCountdown(true);
  //     CountdownTimer && clearInterval(CountdownTimer);
  //     let countdown = dayjs(deadline).diff(dayjs(), 'second');
  //     CountdownTimer = setInterval(() => {
  //       countdown--;
  //       if (countdown <= 0) {
  //         setShowCountdown(false);
  //         CountdownTimer && clearInterval(CountdownTimer);
  //       }
  //     }, 1000);
  //   }
  // }, []);

  // if (showCountdown) return <ReleaseCountdown />;

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        algorithm: common?.theme === 'light' ? roadLightAlgorithm : roadDarkAlgorithm,
        token: {
          colorPrimary: '#C2313A', // 主题色
          colorSuccess: '#00CC66', // 成功色
          colorError: '#FF0000', // 错误色
          // colorFail: '#FF3300', // 失败色
          colorWarning: '#FF9900', // 警告色
          colorLink: '#3399FF', // 链接色
        },
        components: {
          Button: { borderRadius: 8, controlHeightSM: 40, controlHeight: 48, controlHeightLG: 56 },
          Cascader: { controlHeightSM: 40, controlHeight: 48, controlHeightLG: 56 },
          Dropdown: {
            colorBgElevated: common?.theme === 'light' ? '#FFFFFF' : '#171717',
            colorText: common?.theme === 'light' ? '#61616C' : 'rgba(255, 255, 255, 0.65)',
          },
          Form: { controlHeightSM: 40, controlHeight: 48, controlHeightLG: 56 },
          Input: { controlHeightSM: 40, controlHeight: 48, controlHeightLG: 56 },
          Modal: { borderRadius: 24 },
          Radio: { controlHeightSM: 40, controlHeight: 48, controlHeightLG: 56 },
          Select: { controlHeightSM: 40, controlHeight: 48, controlHeightLG: 56 },
        },
      }}
    >
      <Suspense fallback={<Loading />}>{routing}</Suspense>
    </ConfigProvider>
  );
};

export default inject('common', 'language')(observer(App));
