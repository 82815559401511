import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const DashboardLayout = lazy(() => import('@/layouts/DashboardLayout'));
const MainLayout = lazy(() => import('@/layouts/MainLayout'));

const UploadCenter = lazy(() => import('@/pages/UploadCenter')); // 上传中心
const Home = lazy(() => import('@/pages/Home')); // 运营概览
const MaterialManagement = lazy(() => import('@/pages/MaterialManagement')); // 素材管理
const VideoMaterial = lazy(() => import('@/pages/MaterialManagement/VideosMaterial')); // 视频
const ImagesMaterial = lazy(() => import('@/pages/MaterialManagement/ImagesMaterial')); // 图片
const SoundsMaterial = lazy(() => import('@/pages/MaterialManagement/SoundsMaterial')); // 音效
const SubtitlesMaterial = lazy(() => import('@/pages/MaterialManagement/SubtitlesMaterial')); // 字幕
const FontsMaterial = lazy(() => import('@/pages/MaterialManagement/FontsMaterial')); // 字体
const BGMMaterial = lazy(() => import('@/pages/MaterialManagement/BGMMaterial')); // BGM
const BackupSpace = lazy(() => import('@/pages/MaterialManagement/BackupSpace')); // 备份空间

const ContentManagement = lazy(() => import('@/pages/ContentManagement')); // 内容管理
const ContentDetails = lazy(() => import('@/pages/ContentManagement/ContentDetails')); // 内容管理详情页
const PlatformManagement = lazy(() => import('@/pages/PlatformManagement')); // 平台管理
const PlatformDetails = lazy(() => import('@/pages/PlatformManagement/PlatformDetails')); // 平台管理详情页
const SubtitleManagement = lazy(() => import('@/pages/SubtitleManagement')); // 字幕管理
const DataAnaLysis = lazy(() => import('@/pages/DataAnaLysis')); // 数据分析
const CommercialMonetization = lazy(() => import('@/pages/CommercialMonetization')); // 商务变现
const Wallet = lazy(() => import('@/pages/Wallet')); // 硬币钱包
const RepostManagement = lazy(() => import('@/pages/RepostManagement')); // 搬运管理
const CreativeLaboratory = lazy(() => import('@/pages/CreativeLaboratory')); // 创作实验室

const Setting = lazy(() => import('@/pages/Setting')); // 设置
const UserInfo = lazy(() => import('@/pages/Setting/UserInfo')); // 用户信息
const AccountSecurity = lazy(() => import('@/pages/Setting/AccountSecurity')); // 账号安全
const Notification = lazy(() => import('@/pages/Setting/Notification')); // 通知
const Protocol = lazy(() => import('@/pages/Setting/Protocol')); // 协议

const Feedback = lazy(() => import('@/pages/Feedback')); // 反馈
const PersonalSettings = lazy(() => import('@/pages/PersonalSettings')); // 个人设置
const AuthenticationCenter = lazy(() => import('@/pages/AuthenticationCenter')); // 认证中心

const NotFound = lazy(() => import('@/pages/NotFound'));
const SignIn = lazy(() => import('@/pages/SignIn'));
const SignUp = lazy(() => import('@/pages/SignUp'));
const Password = lazy(() => import('@/pages/Password'));
const PlatformOperation = lazy(() => import('@/pages/PlatformOperation'));
const SelectPlatform = lazy(() => import('@/pages/PlatformOperation/Platform'));
const BindAccount = lazy(() => import('@/pages/PlatformOperation/Platform/BindAccount'));
const RegisterAccount = lazy(() => import('@/pages/PlatformOperation/Platform/RegisterAccount'));
const VerifyAccount = lazy(() => import('@/pages/PlatformOperation/Platform/VerifyAccount'));
const DeviceReminder = lazy(() => import('@/pages/DeviceReminder')); // 请使用PC端浏览器
// const HelpCenter = lazy(() => import('@/pages/HelpCenter')); // 客服中心
// const ReleaseCountdown = lazy(() => import('@/pages/ReleaseCountdown')); // 发布倒计时

const routes = [
  {
    path: '/v2',
    element: <DashboardLayout />,
    children: [
      { path: '/v2', element: <Navigate to="/v2/home" /> },
      { path: 'upload-center', element: <UploadCenter /> }, // 上传中心
      { path: 'home', element: <Home /> }, // 运营概览
      // 素材管理
      {
        path: 'material-management',
        element: <MaterialManagement />,
        children: [
          {
            path: '/v2/material-management',
            element: <Navigate to="/v2/material-management/video-material" />,
          },
          { path: 'video-material', element: <VideoMaterial /> },
          { path: 'images-material', element: <ImagesMaterial /> },
          { path: 'sounds-material', element: <SoundsMaterial /> },
          { path: 'subtitles-material', element: <SubtitlesMaterial /> },
          { path: 'fonts-material', element: <FontsMaterial /> },
          { path: 'bgm-material', element: <BGMMaterial /> },
          { path: 'backup-space', element: <BackupSpace /> },
        ],
      },
      // 内容管理
      {
        path: 'content-management',
        children: [
          { path: '', element: <ContentManagement /> },
          { path: 'content-details', element: <ContentDetails /> },
        ],
      },
      // 平台管理
      {
        path: 'platform-management',
        children: [
          { path: '', element: <PlatformManagement /> },
          { path: 'platform-details', element: <PlatformDetails /> },
        ],
      },
      { path: 'subtitle-management', element: <SubtitleManagement /> }, // 字幕管理
      { path: 'data-anaLysis', element: <DataAnaLysis /> }, // 数据分析
      { path: 'commercial-monetization', element: <CommercialMonetization /> }, // 商务变现
      { path: 'wallet', element: <Wallet /> }, // 硬币钱包
      { path: 'repost-management', element: <RepostManagement /> }, // 搬运管理
      { path: 'creative-laboratory', element: <CreativeLaboratory /> }, // 创作实验室
      {
        path: 'setting',
        element: <Setting />,
        children: [
          {
            path: '/v2/setting',
            element: <Navigate to="/v2/setting/user-info" />,
          },
          { path: 'user-info', element: <UserInfo /> },
          { path: 'account-security', element: <AccountSecurity /> },
          { path: 'notification', element: <Notification /> },
          { path: 'protocol', element: <Protocol /> },
        ],
      }, // 设置
      { path: 'feedback', element: <Feedback /> }, // 反馈
      { path: 'personal-settings', element: <PersonalSettings /> }, // 个人设置
      { path: 'authentication-center', element: <AuthenticationCenter /> }, // 认证中心
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/v2/home" /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'device-reminder', element: <DeviceReminder /> },
      // { path: 'help-center', element: <HelpCenter /> },
      // { path: 'release-countdown', element: <ReleaseCountdown /> },
    ],
  },
  {
    path: '',
    element: <MainLayout />,
    children: [
      { path: 'signin', element: <SignIn /> },
      { path: 'signup', element: <SignUp /> },
      { path: 'password', element: <Password /> },
      {
        path: 'platform-operation',
        children: [
          { path: '', element: <PlatformOperation /> },
          {
            path: 'platform',
            children: [
              { path: '', element: <SelectPlatform /> },
              { path: 'bind', element: <BindAccount /> },
              { path: 'register', element: <RegisterAccount /> },
              { path: 'verify', element: <VerifyAccount /> },
            ],
          },
        ],
      },
      { path: '', element: <Navigate to="/v2/home" /> },
    ],
  },
];

export default routes;
