const zh = {
  "global": {
    "language": "中文",
    "customer_service": "客服",
    "customer_service_tips": "如果有问题,请联系{0}",
    "customer_service_subtitle": "请尽可能的详情描述您所遇到的问题,通过邮件联系我们",
    "customer_service_email": "客服邮箱",
    "email_holder": "请输入邮箱",
    "email_error": "邮箱格式错误！",
    "password_holder": "请输入密码",
    "password_error": "8-20字以上的大小写英文，数字，特殊文字（都要包括）",
    "password_confirm_error": "两次输入密码不相同！",
    "phone_holder": "请输入手机号",
    "code_holder": "请输入验证码",
    "code_error": "验证码格式错误！",
    "code_get": "获取验证码",
    "code_resend": "重新发送",
    "agreement_all_agree": "全部同意",
    "agreement_age_14": "本人满14岁以上",
    "agreement_age_18": "本人满18岁以上",
    "protocol_checkbox_user": "{0}用户协议",
    "protocol_checkbox_privacy": "{0}隐私协议",
    "agreement_message_tip": "需要先同意协议",
    "btn_add": "添加",
    "btn_change": "修改",
    "btn_delete": "删除",
    "btn_confirm": "确认",
    "btn_consider": "再考虑下",
    "btn_close": "关闭",
    "btn_submit": "提交",
    "btn_cancel": "取消",
    "btn_know": "知道了",
    "btn_last_step": "上一步",
    "btn_next_step": "下一步",
    "btn_agree": "同意",
    "btn_disagree": "不同意",
    "btn_go": "前往",
    "btn_signin": "登录",
    "btn_google_signin": "使用谷歌登录",
    "btn_signup": "创建个人账户",
    "btn_send": "发送",
    "btn_verify": "验证",
    "btn_done": "完成",
    "btn_save": "保存",
    "btn_put_away": "收起",
    "btn_expand": "展开",
    "btn_edit": "编辑",
    "modal_open_platform_title": "还没有开通平台？",
    "modal_open_platform_subtitle": "立即去开通平台，已享受完整的用户体验！",
    "modal_open_platform_btn": "去开通",
    "total_count": "共{0}条数据",
    "list_no_more": "已加载全部数据",
    "message_success_create": "创建成功！",
    "message_error_create": "创建失败！",
    "message_success_update": "修改成功！",
    "message_error_update": "修改失败！",
    "message_success_delete": "删除成功！",
    "message_error_delete": "删除失败！",
    "message_success_upload": "上传成功！",
    "message_error_upload": "上传失败！",
    "message_success_bind": "绑定成功!",
    "message_error_bind": "绑定失败!",
    "message_no_token": "登录超时，请重新登录！",
    "message_no_permission": "没有权限！",
    "message_sms_sended": "短信验证码已发送！",
    "message_sms_error": "短信验证码发送失败！",
    "message_email_sended": "邮箱验证码已发送！",
    "message_email_error": "邮箱验证码发送失败！",
    "message_verify_success": "验证成功！",
    "message_verify_fail": "验证失败！",
    "not_found_title": "没有您要找的页面",
    "not_found_tips": "可能是路径错误导致",
    "empty_no_data": "暂无数据",
    "empty_on_dev": "敬请期待",
    "upgrading_tip": "升级中，敬请期待",
    "upload_avatar": "上传头像",
    "device_reminder_content": "adobaro.com 暂时不支持手机端\n请电脑模式或者电脑端进来",
    "ready_subtitle": "升级中，请稍等",
    "platform_hao_kan": "好看视频",
    "platform_tencent": "腾讯视频",
    "platform_iqiyi": "爱奇艺",
    "platform_bilibili": "哔哩哔哩",
    "platform_xi_gua": "西瓜视频",
    "platform_wei_bo": "微博",
    "platform_youtube": "Youtube",
    "platform_dou_yin": "抖音",
    "platform_red_book": "小红书",
    "platform_kuai_shou": "快手",
    "platform_cloud_music": "网易云音乐",
    "platform_wechat_video": "微信视频号",
    "platform_mei_pai": "美拍",
    "platform_da_yu": "大鱼号",
    "platform_dian_tao": "点淘",
    "platform_qq_world": "QQ小世界",
    "platform_tiktok": "TikTok",
    "platform_instagram": "Instagram",
    "platform_twitter": "Twitter",
    "country_USA": "美国",
    "country_CAN": "加拿大",
    "country_KRW": "韩国",
    "country_CHN": "中国"
  },
  "dashboard": {
    "home_page": "首页",
    "material_management": "素材管理",
    "content_management": "内容管理",
    "platform_management": "平台管理",
    "subtitle_management": "字幕管理",
    "data_analysis": "数据分析",
    "commercial_monetization": "商务变现",
    "wallet": "我的钱包",
    "repost_management": "搬运管理",
    "creative_laboratory": "创作实验室",
    "setting": "设置",
    "feedback": "反馈",
    "close_sidebar": "收起侧边栏",
    "upload_video": "上传视频",
    "lang_language": "语言",
    "lang_zh_cn": "中文(简体)",
    "lang_ko_kr": "한국어",
    "lang_en_eu": "English",
    "auth": "创作者认证",
    "auth_certified": "已认证",
    "auth_uncertified": "未认证",
    "account_management": "多账号管理",
    "sign_out": "退出登录"
  },
  "sign": {
    "signin_title": "欢迎来到adobaro",
    "signin_subtitle": "从开通平台到收益结算，在adobaro内满足所有中文平台运营",
    "email_holder": "请输入邮箱",
    "email_error": "邮箱格式错误！",
    "signin_email_holder": "请输入手机号或邮箱",
    "signin_email_error": "手机号或邮箱格式错误",
    "forgot_password": "忘记密码?",
    "signup": "注册",
    "signup_tip": "还没有账号？立即{0}",
    "account_not_exist": "找不到信息,请重新确认登录ID",
    "signin_error": "登录失败",
    "google_signin_error": "谷歌登录失败",
    "signin_warning": "我们发现您的登录存在安全问题，您需要查看您的电子邮件并验证您的安全性",
    "signin_fail": "密码错误",
    "forgot_title": "忘记密码了吗？",
    "forgot_subtitle": "请输入您注册时使用的电子邮箱。更改密码的验证将发送到您的电子邮箱。",
    "forgot_btn": "发送验证邮件",
    "forgot_email_send": "邮件已发送,请前往邮箱进行下一步操作",
    "forgot_email_send_error": "发送邮件失败",
    "verify_success": "验证成功",
    "verify_fail": "验证失败",
    "verify_success_tip": "您已验证成功，账号已解除安全登录限制。",
    "verify_fail_tip": "请检查您的链接或验证已超时。",
    "btn_signin_retry": "重新登录",
    "account_exist": "账号已注册",
    "signup_age_14": "本人满14岁以上",
    "signup_user_protocol": "adobaro用户协议",
    "signup_privacy_protocol": "adobaro隐私政策",
    "signup_marketing_protocol": "(可选) 营销通知",
    "signin": "登录",
    "signin_tip": "已有账号？去{0}",
    "signup_email_send": "邮件已发送,请前往邮箱进行下一步操作",
    "signup_email_send_error": "创建账户失败",
    "password_set": "设置密码",
    "password_reset": "设置新密码",
    "password_set_subtitle": "通过邮箱已经认证过本人操作，请设置密码",
    "password_reset_subtitle": "通过邮箱已经认证过本人操作，请设置新密码",
    "btn_password_set": "设置密码",
    "btn_password_reset": "重置密码",
    "password_set_success": "设置密码成功",
    "password_reset_success": "重置密码成功",
    "password_set_fail": "设置密码失败",
    "password_reset_fail": "重置密码失败",
    "password_set_code_invalid": "验证码已失效,请重新注册",
    "password_reset_code_invalid": "验证码已失效,请重新重置密码"
  },
  "auth": {
    "modal_title": "需要Global认证",
    "modal_subtitle": "Global认证后，可以获得",
    "modal_plus_title": "需要Global Plus认证",
    "modal_plus_subtitle": "Global Plus认证后，可以获得",
    "modal_item1": "· 开通更多平台",
    "modal_item2": "· 开通平台收益",
    "modal_item3": "· 开通数据分析",
    "modal_item4": "· 开通功能",
    "modal_btn_next": "下次吧",
    "modal_btn_go": "去认证",
    "auth_center": "认证中心",
    "auth_center_subtitle": "管理您的认证信息",
    "auth_global": "Global Pass",
    "auth_creator": "Global Plus Pass",
    "auth_history": "认证历史",
    "auth_global_subtitle": "为保证收益以及账号安全提升",
    "auth_creator_subtitle": "为条件满足的创作者，提供更多的曝光以及机会",
    "auth_history_subtitle": "可以查看认证使用记录",
    "auth_global_title": "Global认证",
    "auth_creator_title": "Global Plus认证",
    "auth_history_title": "认证历史",
    "creator_auth_tip": "请在Global认证完成后,再提交Global Plus认证",
    "pass_title": "认证通过",
    "passport_title": "护照信息",
    "platform_title": "平台信息",
    "platform_name": "平台名称",
    "platform_url": "平台链接",
    "passport_dashboard_tip": "护照扫描件",
    "passport_handhold_tip": "手持护照照片",
    "platform_dashboard_tip": "个人主页截图",
    "platform_handhold_tip": "平台后台与本人合照",
    "btn_submit_picture": "同意协议并提交认证",
    "protocol_tip": "请阅读 {0}，申请认证并同意协议",
    "protocol_privacy": "保护个人信息协议",
    "protocol_platform": "adobaro委托协议书",
    "pass_no": "认证号",
    "pass_duration": "认证期限",
    "pass_explain": "{0}通过您上传的护照信息认证，为了保护您的信息无法公开",
    "pass_in_review": "您的{0}认证申请已提交,等待审核中，处理时间工作日3-5日，请稍等",
    "pass_in_fail": "您的{0}申请审核失败,失败原因: {1}",
    "pass_expire_warning": "您的{0}即将在{1}过期,请尽快重新提交认证",
    "pass_expire_already": "您的{0}认证已过期,请重新提交认证",
    "pass_submit_success_tip": "您的{0}认证已提交,等待审核中，处理时间工作日3-5日，请稍等",
    "pass_submit_fail_tip": "您的{0}认证提交失败",
    "auth_attention": "认证注意事项",
    "global_attention_question_1": "1、为什么需要护照？",
    "global_attention_answer_1": "为保证平台收益以及账号安全提升",
    "global_attention_question_2": "1、为什么需要护照？",
    "global_attention_answer_2": "为保证平台收益以及账号安全提升",
    "global_attention_question_3": "1、为什么需要护照？",
    "global_attention_answer_3": "为保证平台收益以及账号安全提升",
    "global_attention_question_4": "1、为什么需要护照？",
    "global_attention_answer_4": "为保证平台收益以及账号安全提升",
    "global_attention_pass_question_1": "1、认证通过后，怎么取消global认证？",
    "global_attention_pass_answer_1": "为保证平台收益以及账号安全提升，认证取消申请后所有平台停止收益以及注销账号，如果还是想要认证请按钮下面",
    "global_attention_pass_question_2": "2、认证通过后，怎么取消global认证？",
    "global_attention_pass_answer_2": "为保证平台收益以及账号安全提升，认证取消申请后所有平台停止收益以及注销账号，如果还是想要认证请按钮下面",
    "creator_attention_question_1": "1、为什么需要护照？",
    "creator_attention_answer_1": "为保证平台收益以及账号安全提升",
    "creator_attention_question_2": "1、为什么需要护照？",
    "creator_attention_answer_2": "为保证平台收益以及账号安全提升",
    "creator_attention_question_3": "1、为什么需要护照？",
    "creator_attention_answer_3": "为保证平台收益以及账号安全提升",
    "creator_attention_question_4": "1、为什么需要护照？",
    "creator_attention_answer_4": "为保证平台收益以及账号安全提升",
    "creator_attention_pass_question_1": "1、认证通过后，怎么取消Global Plus认证？",
    "creator_attention_pass_answer_1": "为保证平台收益以及账号安全提升，认证取消申请后所有平台停止收益以及注销账号，如果还是想要认证请按钮下面",
    "creator_attention_pass_question_2": "2、认证通过后，怎么取消global认证？",
    "creator_attention_pass_answer_2": "为保证平台收益以及账号安全提升，认证取消申请后所有平台停止收益以及注销账号，如果还是想要认证请按钮下面",
    "attention_know_more": "了解更多",
    "cancel_auth": "取消认证",
    "cancel_auth_title": "您确定要取消认证吗？",
    "cancel_auth_subtitle": "取消认证后所有账号收益立即停止，同时注销平台账号，确认吗？",
    "cancel_auth_success": "取消认证成功",
    "cancel_auth_fail": "取消认证失败",
    "attention_approve": "对答案满意吗？",
    "history_country": "国家",
    "history_platform": "平台",
    "history_auth_type": "认证",
    "history_use_todo": "用处",
    "history_timestamp": "时间",
    "use_type_handling": "搬运处理",
    "use_type_open_income": "开通收益",
    "use_type_open_platform": "开通平台"
  },
  "home": {
    "play_more_title": "多玩多得",
    "play_more_new": "新手用户浏览玩法",
    "play_more_email": "绑定邮箱 轻松登录",
    "play_more_platform": "开通平台 一键分发",
    "play_more_video": "发布视频 见面世界",
    "tour_upload": "这里可以上传和发布视频",
    "tour_material": "管理已经上传的素材文件",
    "tour_content": "管理发布的内容",
    "tour_platform": "管理和开通平台",
    "tour_coin": "管理和查看a.coin 使用情况",
    "tour_account": "设置账户相关信息",
    "unlock_describe": "解锁将获得{0}个币",
    "play_more_done": "已完成",
    "play_more_unlock": "立即解锁",
    "read_more": "查看更多",
    "all_platform_statistic": "全平台数据统计",
    "statistic_date": "数据统计于{0}",
    "statistic_platform": "开通平台",
    "all_platform_fans": "全平台粉丝数",
    "all_platform_plays": "全平台播放量",
    "latest_content": "最新内容",
    "latest_popular": "最人气平台",
    "all_coin": "总金币",
    "coin_rule": "金币规则",
    "read_details": "查看详情",
    "coin": "币",
    "platform_news": "平台新闻",
    "news_content": "欢迎再次来到adobaro！\n在平台新闻中，我们将为您介绍有关adobaro的故事和新功能。希望您能给予更多关注。",
    "news_help_btn": "浏览帮助中心",
    "unlocked_get_coin": "未解锁，浏览任务卡解锁可获得{0}个金币",
    "unlocked_task_card": "浏览任务卡",
    "unlocked_to_content": "去内容管理",
    "unlocked_to_upload": "发布视频",
    "unlocked_to_open": "开通平台",
    "unlocked_upload_tips": "需要先开通平台才能解锁此项功能哦，立即去"
  },
  "upload": {
    "file_btn_status_removed": "上传文件",
    "file_btn_status_uploading": "上传中",
    "file_btn_status_done": "更换文件",
    "file_btn_status_error": "重新上传",
    "file_dragger_subtitle": "拖拽文件到此处也可上传",
    "file_dragger_tip": "您可上传{0}类型的视频",
    "file_status_uploading": "已上传:",
    "file_status_done": "已完成",
    "file_status_error": "上传失败",
    "file_type_error": "文件格式只支持{0}",
    "file_limit_size": "文件需小于{0}MB",
    "file_limit_size_tip": "({0}M以内)",
    "file_change_tip": "更换文件",
    "file_upload_image_tip": "上传图片",
    "file_change_image_tip": "更换图片",
    "file_name_already_exist": "文件名重复,请修改文件名后重新上传",
    "file_size_exceeds_capacity": "您的素材管理存储空间不足,请检查存储空间",
    "example_pic_tip": "示例图",
    "guide_tips": "您只需要准备{0}。",
    "guide_tip_content": "原文标题、简介、原视频、封面",
    "guide_subtitle": "adoba AI 帮您当地语言的标题、当地语言的简介\r、最适合平台的封面\r、最适合平台的标签等最适合的操作",
    "guide_upload_btn": "立即发布视频",
    "page_title": "发布设置",
    "page_subtitle": "投稿视频到已开通的平台",
    "upload_file_holder": "请上传文件",
    "upload_file_complete_tip": "请等待文件上传完成后再点击投稿",
    "select_platform": "选择发布平台",
    "select_platform_holder": "请选择发布平台",
    "open_more_platform": "开通更多平台",
    "basic_info": "基本设置",
    "basic_info_tip": "您只需填写基本信息，adoba AI操作剩下信息",
    "basic_content_title": "内容标题",
    "basic_content_title_holder": "请输入标题",
    "basic_content_cover": "内容封面",
    "basic_content_cover_holder": "请上传内容封面",
    "basic_content_cover_upload": "您可上传{0}格式的封面图片",
    "basic_content_cover_upload_tip": "封面里不含字幕，更好优化当地平台的封面",
    "basic_content_cover_change_tip": "更换封面",
    "basic_content_intro": "内容简介",
    "basic_content_intro_holder": "请输入简介",
    "basic_content_subtitle": "选择字幕",
    "basic_content_subtitle_holder": "请选择字幕",
    "basic_content_timer_release": "定时发布",
    "basic_content_timer_release_tip": "只能选择+{0}个小时以后的时间",
    "btn_release": "立即投稿",
    "btn_draft": "存草稿",
    "draft_title": "草稿提醒",
    "draft_subtitle": "检测到草稿箱中有未提交的稿件,是否读取?",
    "draft_read_image_error": "读取封面图片失败,请稍后重试",
    "draft_save_tips": "需要先上传视频或等待视频上传完成后,才能保存到草稿箱",
    "draft_save_success": "保存草稿成功",
    "release_content_success": "投稿成功，等待内容审核.\n审核通过后才能完成上传，请留意邮件通知",
    "release_content_fail": "投稿失败",
    "release_free_count": "您本周剩余{0}次免费投稿次数",
    "release_limit_tip_1": "1. 投稿视频每次需要 {0} a.coin",
    "release_limit_tip_2": "2. 正常用户每周有1次免费投稿次数",
    "release_limit_tip_3": "3. Global Pass用户每周有3次免费投稿次数",
    "release_limit_tip_4": "4. Global Plus Pass用户每周有5次免费投稿次数"
  },
  "material": {
    "page_title": "素材管理",
    "page_subtitle": "关于帮助您的创作，所有素材管理到这里",
    "sider_my_file": "我的文件",
    "sider_enlarge_capacity": "扩容",
    "menu_video": "视频",
    "menu_images": "图片",
    "menu_sounds": "音效",
    "menu_subtitles": "字幕",
    "menu_fonts": "字体",
    "menu_bgm": "BGM",
    "menu_backup_space": "备份空间",
    "alert_capacity": "您素材管理的总量马上上限，会有影响使用，可以升级总量更方便使用，要了解一下吗？",
    "alert_upgrade": "升级总量",
    "all_backups": "统一备份",
    "all_delete": "统一删除",
    "all_download": "统一下载",
    "confirm_title_delete": "确定删除吗？",
    "confirm_ok": "确认",
    "confirm_cancel": "再考虑",
    "selected_items": "已选中{0}个文件",
    "backup_btn": "备份",
    "delete_btn": "删除",
    "download_btn": "下载",
    "pagination_total": "共{0}条数据",
    "video_title": "全部视频",
    "video_delete_confirm": "您确定删除这{0}个视频吗？",
    "video_deleted_description": "删除之后的将不能分发视频到其他平台",
    "video_name": "名称",
    "video_size": "大小",
    "video_published_platform": "发布平台",
    "video_opened_platform": "开通平台",
    "image_title": "全部图片",
    "subtitles_title": "全部字幕",
    "subtitles_delete_confirm": "您确定删除这{0}个文件吗？",
    "subtitles_deleted_description": "删除之后的将不能分发视频到其他平台",
    "subtitles_name": "名称",
    "subtitles_size": "大小",
    "subtitles_lang": "语言",
    "modal_title": "修改名称",
    "modal_name_label": "名称",
    "modal_name_holder": "请输入名称"
  },
  "content": {
    "page_title": "内容管理",
    "page_subtitle": "管理您上传的各平台的内容",
    "radio_all": "全部",
    "radio_play": "最多播放",
    "radio_like": "最多点赞",
    "page_details_title": "视频详情",
    "base_info": "基础信息",
    "base_video": "视频",
    "base_cover": "封面",
    "base_title": "标题",
    "base_subtitle": "字幕",
    "base_upload_date": "上传时间",
    "empty_cover": "暂无封面",
    "distributed_platform": "发布平台",
    "distributed_name": "平台",
    "distributed_state": "状态",
    "distributed_subtitle": "字幕",
    "distributed_plays": "播放数",
    "distributed_likes": "点赞数",
    "distributed_upload_date": "发布时间",
    "distributed_action": "操作",
    "status_playing": "播放中",
    "status_opening": "进行中",
    "status_failure": "发布失败",
    "status_unpublished": "未发布",
    "status_not_opened": "未开通",
    "action_operate": "操作",
    "action_ask": "询问",
    "action_publish": "一键发布",
    "action_open": "立即开通",
    "action_operate_check": "查看",
    "action_operate_analysis": "分析",
    "action_operate_edit": "修改",
    "action_operate_delete": "删除",
    "republish_platform_success": "发布成功",
    "republish_platform_error": "发布失败",
    "video_url_error": "获取视频链接失败"
  },
  "platform": {
    "guide_title": "是否有运营账号?",
    "guide_subtitle": "欢迎您设置运营账号,以便帮您分析推荐中国运营平台",
    "guide_platform_holder": "请选择平台",
    "guide_platform_url_holder": "请输入Channel ID",
    "guide_platform_url_error": "您输入的Channel ID有误,请检查您的Channel ID",
    "guide_platform_already": "您已填写过平台信息",
    "guide_platform_confirm": "Channel ID提交后不可修改.\n请检查并确认您填写的Channel ID!",
    "guide_platform_tip": "怎么查看相关运营账号的Channel ID?",
    "guide_btn_recommend": "推荐",
    "guide_platform_error": "推荐平台失败",
    "guide_skip_title": "暂时跳过开通平台",
    "guide_skip_subtitle": "这里是选择原因的说明",
    "guide_skip_holder": "请选择跳过原因",
    "guide_skip_reason_1": "没有运营账号",
    "guide_skip_reason_2": "已经开通过账号",
    "guide_skip_reason_3": "没有想要的国家平台",
    "guide_skip_reason_4": "其他原因",
    "guide_skip_btn": "进入首页",
    "guide_analysis_title": "推荐分析中",
    "guide_analysis_subtitle": "分析完毕自动跳转推荐页面",
    "guide_analysis_fail_title": "目前太多人排队",
    "guide_analysis_fail_subtitle": "分析完毕后，通过邮箱通知您",
    "guide_analysis_fail_btn_next": "下次吧",
    "guide_analysis_fail_btn_confirm": "通知我",
    "guide_analysis_done_title": "分析完毕",
    "guide_analysis_done_subtitle": "根据您选择的平台，推荐您开通以下中国平台",
    "guide_analysis_platform_about": "了解平台",
    "guide_analysis_btn_open": "开通平台账号",
    "guide_analysis_btn_already": "已有相关平台账号",
    "guide_analysis_btn_next": "下次开通",
    "guide_recommend_fail": "开通平台失败",
    "guide_bind_title": "绑定账号",
    "guide_bind_subtitle": "绑定运营账号，以便后续运营平台",
    "guide_bind_tip": "为什么需要账号密码？",
    "guide_bind_btn_confirm": "立即绑定",
    "guide_bind_btn_next": "下次绑定",
    "guide_bind_success": "您已提交平台信息,稍后将邮件通知您绑定结果",
    "guide_open_phone_title": "注册平台",
    "guide_open_phone_subtitle": "中国运营平台开通需要手机号验证，以便完成注册等操作",
    "guide_open_btn_verify": "开始验证",
    "guide_open_submit_success": "您已提交手机号,等待后台处理中.请注意查看邮件通知",
    "guide_open_send_sms_error": "发送验证码失败",
    "guide_open_verify_title": "填写验证码",
    "guide_open_verify_subtitle": "请查收短信，短信内容如下",
    "guide_open_btn_verifying": "验证",
    "guide_open_verify_success": "您已提交验证,稍后将邮件通知您开通结果",
    "guide_open_verify_error": "验证失败",
    "manage_title": "平台管理",
    "manage_subtitle": "这里可以管理开通的平台和要开通的平台",
    "applied_platform": "已申请平台",
    "btn_refresh": "刷新平台状态",
    "btn_refresh_tip": "点击此处可以刷新平台状态",
    "btn_all": "全部",
    "btn_play_most": "最多播放",
    "btn_like_most": "最多点赞",
    "platform_empty": "您还没有开通平台,点击下面的平台一键开通",
    "status_done": "开通完成",
    "status_opening": "开通中，请稍等",
    "status_verifying": "验证中",
    "status_verify_failed": "验证失败",
    "status_failed": "开通失败",
    "btn_status_done": "发布视频",
    "btn_status_verify": "填写验证码",
    "btn_status_failed": "查看详情",
    "un_apply_platform": "未申请平台",
    "open_count": " {0} 名用户已经开通",
    "lastest_open": "用户{0} {1}开通",
    "btn_open": "一键开通",
    "btn_reapply": "重新申请",
    "open_platform_title": "开通平台",
    "open_platform_subtitle": "简单安全地开通复杂而繁忙的中国平台",
    "details_title": "平台概览",
    "details_info_title": "基础信息",
    "details_info_avatar": "头像",
    "details_info_intro": "简介",
    "details_info_intro_empty": "暂无简介",
    "details_info_intro_holder": "请输入简介",
    "details_info_date": "账号注册时间",
    "details_info_date_empty": "暂无数据",
    "details_info_date_holder": "请选择注册时间",
    "details_info_classify": "账号分类",
    "details_info_classify_empty": "暂无数据",
    "details_info_classify_holder": "请选择分类",
    "details_info_income": "平台收益",
    "details_info_order": "接单",
    "details_info_btn_again": "再次推荐",
    "details_info_btn_open": "立即开通",
    "details_info_btn_edit": "修改基础信息",
    "details_info_btn_submit": "保存并修改",
    "details_data_overview_title": "数据概览",
    "details_data_radio_yesterday": "昨天",
    "details_data_radio_nearly7": "近7天",
    "details_data_radio_nearly30": "近30天",
    "details_data_fans": "累计粉丝数",
    "details_data_likes": "累计点赞数",
    "details_data_platform": "累计平台收益",
    "details_data_published": "累计发布",
    "details_data_plays": "累计播放数",
    "details_data_ad": "累计广告收益",
    "details_release_video_title": "发布视频"
  },
  "wallet": {
    "title": "a.coin 钱包",
    "subtitle": "a.coin 是一种用于 adobaro 的平台加密货币。",
    "overview_title": "a.coin 数据概览",
    "overview_statistic_title": "总a.coin ",
    "overview_btn_deposit": "充值",
    "overview_btn_withdrawal": "提现",
    "record_title": "a.coin 交易记录",
    "record_enter": "转入记录",
    "record_out": "转出记录",
    "enter_date": "转入时间",
    "enter_content": "内容",
    "enter_all": "全部",
    "out_date": "转出时间",
    "out_content": "内容",
    "out_all": "全部",
    "coin_unit": "币",
    "scene_init_account": "注册赠送",
    "scene_open_platform": "开通平台",
    "scene_bind_platform": "绑定平台",
    "scene_new_video": "发布视频",
    "scene_push_video": "分发视频",
    "scene_withdrawal": "提现",
    "scene_recharge": "充值",
    "scene_platform_settlement": "平台结算",
    "scene_gift": "提前预定赠送",
    "get_tips": "奖励已发放至 [我的钱包]",
    "get_rule_title": "a.coin介绍",
    "get_rule_content": "是站内通过虚拟金币，可以为账号成长使用，也可以申请提现把你的收益更多",
    "cost_use_btn": "使用",
    "cost_insufficient_title": "很遗憾",
    "cont_insufficient_subtitle": "您的可用a.coin不足",
    "cost_insufficient_tips": "本次需要{0}a.coin，您只有{1}a.coin"
  },
  "setting": {
    "title": "设置",
    "subtitle": "进行adobaro相关设置",
    "user_info": "用户信息",
    "account_security": "账号安全",
    "notification": "通知",
    "protocol": "协议",
    "user_uid": "UID",
    "user_phone": "手机",
    "user_email": "邮箱",
    "user_password": "密码",
    "user_third_party_login": "第三方登录",
    "user_not_set": "未设置",
    "user_set_password": "修改密码",
    "user_to_change": "变更",
    "user_bound": "已绑定",
    "user_to_bound": "绑定",
    "user_bound_google": "已绑定谷歌账号",
    "user_unbound_google": "未绑定谷歌账号",
    "user_problem_center": "问题中心",
    "user_phone_title_bind": "请输入已绑定手机号",
    "user_phone_subtitle_bind": "为了你的账户安全，请验证身份。验证成功后进行下一步操作。",
    "user_phone_title_new": "请输入新手机号",
    "user_login_country_check": "请选择国家！",
    "user_modify_phone_verify_fail": "手机号验证失败！",
    "user_support_email": "客服邮箱",
    "user_code_not_received_tip": "如果您未收到验证码,请联系客服处理",
    "user_code_not_received": "没收到验证码？",
    "user_verify_header": "身份验证",
    "user_modify_phone_header": "更改手机号",
    "user_modify_email_update_button": "更新邮箱",
    "user_modify_email_explanation": "为了你的账户安全，请先输入当前邮箱进行验证。",
    "user_modify_email_old_holder": "请输入当前邮箱",
    "user_modify_email_old_popover": "如果您不记得您加入的电子邮件地址，请向客服中心咨询",
    "user_modify_email_forgot": "忘记邮箱？",
    "user_modify_email_verify_fail": "邮箱验证失败！",
    "user_modify_new_email": "新邮箱",
    "user_modify_email_new_holder": "请输入新邮箱",
    "user_modify_email_title": "更改邮箱",
    "user_modify_password_change_button": "更新密码",
    "user_modify_password_title": "更改密码",
    "user_modify_password_explanation": "为了你的账户安全，请先输入邮箱进行验证",
    "user_modify_new_password": "新密码",
    "user_modify_password_new_holder": "请输入新密码",
    "user_modify_password_confirm_holder": "请再次输入密码",
    "user_modify_password_head": "更新密码",
    "security_signin": "登录安全",
    "security_credit": "信用分",
    "security_signin_date": "登录时间",
    "security_signin_mode": "登录方式",
    "security_signin_third": "第三方登录",
    "security_signin_google": "谷歌授权登录",
    "security_signin_account": "账号登录",
    "security_signin_ip": "IP地址",
    "security_signin_country": "国家",
    "security_signin_action": "操作",
    "delete_confirm_title": "确认删除吗？",
    "credit_action_date": "操作时间",
    "credit_action_behavior": "行为",
    "credit_ score": "信用分",
    "notice_spread": "优惠推广通知",
    "notice_platform": "平台通知",
    "notice_sms": "短信通知",
    "notice_email": "邮箱通知",
    "notice_adobaro": "adobaro",
    "notice_operate": "运营平台",
    "notice_select_holder": "请选择",
    "notice_agree": "同意",
    "notice_disagree": "不同意",
    "user_problem_question_1": "1、手机号码变更后，无法使用以前手机号码",
    "user_problem_answer_1": "为账号安全性，需要以前手机号码收验证码，才能换新手机号",
    "user_problem_question_2": "2、为什么看不到全邮箱？",
    "user_problem_answer_2": "为账号安全性，需要验证邮箱之后可以看到全邮箱名",
    "user_problem_question_3": "3、为什么看不到全密码？",
    "user_problem_answer_3": "为账号安全性，需要验证密码，收到手机验证码之后可以看到",
    "user_problem_question_4": "4、为什么我已经有第三方登录？",
    "user_problem_answer_4": "当前选择使用第三方登录，系统会自动绑定登录邮箱",
    "protocol_user": "adobaro用户协议",
    "protocol_privacy": "adobaro隐私政策",
    "protocol_marketing": "adobaro营销通知书"
  },
  "email": {
    "1": "标题、简介不通过选项",
    "2": "敏感、或含有不良词汇、涉及违规内容文字",
    "3": "与视频内容不符",
    "4": "含有触犯中国法律的文字、词汇",
    "6": "封面不过审",
    "7": "封面涉及违规内容：透露生殖器、敏感部位；疑引人不适的画面；涉及低俗内容、辱骂性词汇、违背公序良俗",
    "9": "封面严重拉伸、封面花屏、亮度过低/过分曝光",
    "10": "封面清晰度太差",
    "11": "封面和与视频内容不符",
    "13": "背景抠图与人物不融洽，低质拒绝",
    "14": "封面存在导流内容、站外信息",
    "15": "内容不过审",
    "16": "内容质量问题",
    "17": "视频清晰度差；花屏、亮度、对比度、分辨率、画面模糊无法辨别主体情况",
    "18": "视频死链、404、时长小于6s",
    "19": "视频内容情节明显缺失",
    "20": "视频内容无意义",
    "21": "视频流畅度差，卡顿",
    "22": "画面明显晃屏或者持续晃动5s以上，持续出现3s以上闪屏，影响正常观看",
    "23": "画面主体不完整，被持续（≥3s）截断，严重拉伸/压缩，双边黑框，黑框填充，截断问题",
    "24": "内容全程无声，需要拒绝",
    "25": "片头和片尾黑幕不得超过3s",
    "26": "低质图集",
    "27": "视频内容涉嫌法律问题",
    "28": "视频内容涉嫌侮辱中国国家领导人、公检法军、国家机关、国徽国旗等形象或词语 ，触犯中国法律",
    "29": "视频内容存在违法违规、低俗色情、血腥恐怖相关元素",
    "30": "视频内容存在邪教宗教、封建迷信、反动组织等相关元素",
    "31": "视频内容危害未成年人或残疾人身心健康的内容",
    "32": "未成年人不能作为代言人拍摄商业营销内容",
    "33": "视频内容违反公序良俗、社会价值观相关元素，如出轨、家暴、炫富、歧视、引战、抽烟、脏话、整蛊、恶搞、虐待、喝酒、纹身等",
    "34": "视频内容存在性暗示行为、低俗场景、低俗舞蹈、不正当男女关系等",
    "35": "视频内容穿着暴露、轮廓明显、露点等",
    "36": "视频声音质量问题",
    "37": "突然无声、消音、音轨不连贯、声音叠加出现、 声音不相关、声画严重不同步",
    "38": "杂音干扰",
    "39": "字幕质量问题",
    "40": "字幕不清晰、乱码、重叠、语句不通顺",
    "41": "外文视频没有中文字幕、字幕质量问题导致不被推荐",
    "42": "字幕与画面、音频不同步或不相关",
    "43": "字幕截断",
    "44": "字幕全程遮遮挡主体人物脸部",
    "signup_title": "欢迎注册",
    "signup_subtitle": "我们收到注册申请",
    "signup_set_password_tip": "为了账号安全，通过邮箱设置密码",
    "signup_set_password": "设置密码",
    "sigin_warning_title": "我们发现问题",
    "sigin_warning_subtitle": "我们发现您登录的地方是新地方",
    "sigin_warning_midtitle": "如果本人可以按钮下面验证登录",
    "sigin_warning_verify": "验证登录",
    "sigin_warning_not_myself": "如果不是本人操作的话",
    "sigin_warning_change_pwd": "尽快变更您的密码",
    "sigin_warning_pwd": "变更密码",
    "reset_pwd_title": "重置密码",
    "reset_pwd_subtitle": "我们收到重置密码申请",
    "reset_pwd_midtitle": "为了账号安全，通过邮箱设置密码",
    "reset_pwd_set": "设置密码",
    "release_title": "发布状态",
    "release_audit_fail": "审核失败",
    "release_upload_video": "您上传的视频",
    "release_audit_reason": "审核失败了, 失败原因",
    "release_re_upload": "重新上传",
    "release_success": "成功发布",
    "release_up_video": "您上传的视频",
    "release_up_platform": "已经发布到{{.Platform}}平台了",
    "release_view_data": "查看视频数据",
    "op_notice_title": "开通平台状态",
    "op_notice_fail": "开通失败",
    "op_notice_fail_platform": "您申请的{{.Platform}}平台开通失败",
    "op_notice_fail_reason": "查看失败原因",
    "op_notice_success": "开通成功",
    "op_notice_success_platform": "您申请的{{.Platform}}平台已经开通成功",
    "op_notice_ai": "adoba AI帮您生成最适合的账号名、简介等平台信息",
    "op_notice_view_data": "查看平台信息",
    "certification_title": "认证通知",
    "certification_subtitle_global_fail": "Global认证失败",
    "certification_global_fail": "您申请的Global认证已经失败",
    "certification_subtitle_creator_fail": "Creator认证失败",
    "certification_creator_fail": "您申请的Creator认证已经失败",
    "certification_view_reason": "看原因",
    "certification_in_applying": "再申请可以开通以下功能",
    "certification_global_success": "Global认证通过",
    "certification_subtitle_global_success": "您申请的Global认证已经通过了",
    "certification_open_now": "现在可以开通以下功能",
    "certification_subtitle_creator_success": "Creator认证通过",
    "certification_creator_success": "您申请的Creator认证已经通过了",
    "certification_earnings": "浏览更多收益",
    "certification_auth_cancel": "认证取消通知",
    "certification_cancel_start": "您在{{开始时间}}申请的取消认证已经完毕",
    "certification_get_a_coin": "从申请日开始所有平台收益停止，但a.coin 可以继续获得",
    "certification_a_coin": "了解获得a.coin ",
    "certification_re_register": "如果您再次注册平台",
    "certification_cancel_end": "{{结束时间}}之后才能注册平台",
    "certification_thank": "感谢您的陪伴",
    "captcha_notice_title": "验证码通知",
    "captcha_notice_subtitle": "验证码查收通知",
    "captcha_notice_hello": "您好，用户",
    "captcha_notice_platform": "您的平台",
    "captcha_notice_send_code": "验证码已发送",
    "captcha_notice_platform_secure": "因为平台安全问题，需要您的验证码",
    "captcha_notice_sgin": "请注意查收 <br>\n如果您已经在通过adobaro发送验证码的话，不用本邮件操作",
    "captcha_notice_fill_code": "填写验证码",
    "captcha_notice_not_myself": "如果不是本人申请",
    "captcha_notice_handle": "立即登录adobaro找客服处理",
    "captcha_notice_foot": "本邮件无法回复",
    "verify_code_title": "验证码通知",
    "verify_code_subtitle": "验证码查收通知",
    "verify_code_hello": "您好，用户",
    "verify_code_platform": "您的平台",
    "verify_code_send_code": "验证码已发送",
    "verify_code_resend": "上一次验证码实效，请重新发送",
    "verify_code_security": "因为平台安全问题，需要您的验证码",
    "verify_code_sign": "请注意查收",
    "verify_code_fill_code": "填写验证码",
    "email_subject_sign_account": "[adobaro] 请注册您的帐户",
    "email_subject_reset_pwd": "[adobaro] 请修改您的密码",
    "email_subject_sign_warning": "[adobaro] 登录警告",
    "email_subject_auth": "[adobaro] 认证通知",
    "email_subject_release": "[adobaro] 发布状态",
    "email_subject_verify_code": "[adobaro] 验证码通知",
    "email_subject_op_result": "[adobaro] 平台开通结果",
    "email_snject_verify_email": "[adobaro] 请验证你的邮箱",
    "email_verify_code_subtitle": "本人认证验证码",
    "email_verify_code_hello": "您好，您收到本人认证验证码",
    "email_verify_code_input": "请把下面的验证码输入在adobaro完成认证",
    "email_verify_code_not_myself": "如果不是本人，立即登录adobaro变更邮箱",
    "email_verify_code_handle": "客服不会问本次验证码，不要告诉别人",
    "email_verify_code_recover": "本邮件无法回复",
    "email_verify_code_warning": "有问题，请联系客服",
    "auth_err_1": "护照不清晰/不完整，请重新上传",
    "auth_err_2": "手持护照人物/护照信息不清晰/不完整，请重新上传",
    "auth_err_3": "护照信息过期，请及时更新",
    "auth_err_4": "护照信息与事实不符，请准确核实护照信息",
    "auth_err_5": "护照信息已被使用",
    "auth_err_6": "后台截图不清晰/不完整，请重新上传",
    "auth_err_7": "手持后台截图不清晰/不完整，请重新上传",
    "auth_err_8": "后台信息与填写信息不符，请准确核实信息",
    "auth_err_9": "后台信息已被使用",
    "5 (这个不会存在）": "字数过长",
    "8 （不存在）": "封面有边框",
    "12 (不存在)": "封面文字截断"
  }
};export default zh