import { axios, axios2 } from '@/utils/axios';
import {
  GetFileListParams,
  SingleFileType,
  CreatorUploadFileParams,
  UploadFileParams,
  DeleteBatchParams,
  DownloadBatchFiles,
  UpdateMaterialNameParams,
} from '@/stores/MaterialStore/types';

export interface IGetFileListResponseType {
  count: number;
  items: SingleFileType[];
}

export interface IUploadFileResponse {
  id: number;
  url: string;
  method: string;
}

export interface IGetSingleFileInfoResponse {
  id: number;
  name: string;
  size: string;
  category: string;
  status: string;
  createdAt: string;
  // upload:
}

export interface IDownloadFileResponse {
  url: string;
}

export interface ICategoryStorageSpaceResponse {
  used: string;
  capacity: string;
  video: number;
  image: number;
  font: number;
  subtitle: number;
  bgm: number;
  sound: number;
}

export interface IDownloadBatchFilesResponse {
  items: DownloadBatchFiles[];
}

const materialServices = {
  // 获取文件素材列表 List objects
  getFileList: (params: GetFileListParams) =>
    axios.get<IGetFileListResponseType, IGetFileListResponseType>('/strand/v1/objects', { params }),

  // 创建上传文件素材 Create object
  creatorUploadFile: (params: CreatorUploadFileParams) =>
    axios.post<IUploadFileResponse, IUploadFileResponse>('/strand/v1/objects', params),

  // 上传文件素材
  uploadFile: (params: UploadFileParams) =>
    axios2.put(params.url, params.file, {
      baseURL: '',
      timeout: 86400000,
      headers: {
        'X-Oss-Meta-Id': params.ossMetaId,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': '',
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        params.onProgress && params.onProgress(progress);
      },
    }),

  // 获取单个文件的相关信息 Get object
  getSingleFileInfo: (oid: number) =>
    axios.get<IGetSingleFileInfoResponse, IGetSingleFileInfoResponse>(`/strand/v1/objects/${oid}`),

  // 删除单个文件 Delete object
  deleteSingleFile: (oid: number) => axios.delete(`/strand/v1/objects/${oid}`),

  // 下载单个文件 download object
  downloadSingleFile: (oid: number) =>
    axios.post<IDownloadFileResponse, IDownloadFileResponse>(`/strand/v1/objects/${oid}:download`, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),

  // 下载批量文件 download object
  downloadBatchFiles: (params: DeleteBatchParams) =>
    axios.post<IDownloadBatchFilesResponse, IDownloadBatchFilesResponse>(
      `/strand/v1/objects:batchDownload`,
      params
    ),

  // 批量删除文件 batch delete objects
  deleteBatchFiles: (params: DeleteBatchParams) =>
    axios.post('/strand/v1/objects:batchDelete', params),

  // 获取所有分类空间占用大小 Get status
  getCategoryStorageSpace: () =>
    axios.get<ICategoryStorageSpaceResponse, ICategoryStorageSpaceResponse>(`/strand/v1/status`),

  // 修改名称
  updateMaterialName: (oid: number, params: UpdateMaterialNameParams) =>
    axios.patch(`/strand/v1/objects/${oid}`, params),
};

export default materialServices;
