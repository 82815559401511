import React from 'react';
import { theme } from 'antd';
import Lottie from 'lottie-react';
import loadingAnimation from '@/assets/lottie/loading-dots.json';
import styles from './index.module.less';
import { S } from '@/styles/theme';

// 切换页面时的加载动画
const LoadingScreen: React.FC = () => {
  const { token } = theme.useToken();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className={styles.box} style={S.bgBase(token)}>
      <div className={styles.content}>
        <Lottie {...defaultOptions} className={styles.lottie} />
        <p className={styles.tip} style={S.textLabel(token)}>
          Loading...
        </p>
      </div>
    </div>
  );
};

export default LoadingScreen;
