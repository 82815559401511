const ko = {
  "global": {
    "language": "한국어",
    "customer_service": "고객센터",
    "customer_service_tips": "이용 시 불편한 부분이 있다면 {0}로 연락해 주세요.",
    "customer_service_subtitle": "이용에 불편함을 겪고 계시다면, 이메일을 통해 연락주세요",
    "customer_service_email": "고객센터 이메일",
    "email_holder": "이메일을 입력해 주세요.",
    "email_error": "이메일 형식이 맞지 않습니다.",
    "password_holder": "비밀번호를 입력해 주세요.",
    "password_error": "8~20자 대소문자, 특수문자, 숫자가 최소 1개 이상 포함되어 있어야 합니다.",
    "password_confirm_error": "비밀번호가 일치하지 않습니다.",
    "phone_holder": "핸드폰 번호를 입력해 주세요.",
    "code_holder": "인증번호를 입력해 주세요.",
    "code_error": "인증번호 형식이 맞지 않습니다.",
    "code_get": "인증번호 발송",
    "code_resend": "재발송",
    "agreement_all_agree": "모두 동의합니다.",
    "agreement_age_14": "만 14세 이상입니다.",
    "agreement_age_18": "만 18세 이상입니다.",
    "protocol_checkbox_user": "{0} 플랫폼 가입 이용 약관",
    "protocol_checkbox_privacy": "{0} 플랫폼 개인정보 정책",
    "agreement_message_tip": "약관에 대한 동의가 필요합니다.",
    "btn_add": "추가",
    "btn_change": "수정",
    "btn_delete": "삭제",
    "btn_confirm": "확인",
    "btn_consider": "생각해 보겠습니다.",
    "btn_close": "닫기",
    "btn_submit": "제출",
    "btn_cancel": "취소",
    "btn_know": "알겠습니다.",
    "btn_last_step": "이전",
    "btn_next_step": "다음",
    "btn_agree": "동의",
    "btn_disagree": "비동의",
    "btn_go": "바로가기",
    "btn_signin": "로그인",
    "btn_google_signin": "구글 로그인",
    "btn_signup": "회원가입",
    "btn_send": "발송",
    "btn_verify": "인증",
    "btn_done": "완료",
    "btn_save": "저장",
    "btn_put_away": "접기",
    "btn_expand": "더보기",
    "btn_edit": "수정",
    "modal_open_platform_title": "아직도 안하셨어요?",
    "modal_open_platform_subtitle": "플랫폼 개통을 통해 더 많은 해외 구독자를 만나보세요.",
    "modal_open_platform_btn": "플랫폼 개통 신청",
    "total_count": "총 {0} 개 데이터",
    "list_no_more": "이미 모든 데이터를 확인했습니다.",
    "message_success_create": "신규 추가 성공",
    "message_error_create": "신규 추가 실패",
    "message_success_update": "수정 성공",
    "message_error_update": "수정 실패",
    "message_success_delete": "삭제 성공",
    "message_error_delete": "삭제 실패",
    "message_success_upload": "업로드 성공",
    "message_error_upload": "업로드 실패",
    "message_success_bind": "연동 성공",
    "message_error_bind": "연동 실패",
    "message_no_token": "로그인 시간이 초과되었습니다.다시 로그인 해 주세요.",
    "message_no_permission": "권한이 없습니다.",
    "message_sms_sended": "인증번호가 발송되었습니다.",
    "message_sms_error": "인증번호 발송이 실패했습니다.",
    "message_email_sended": "이메일로 인증번호를 발송했습니다.",
    "message_email_error": "이메일 발송이 실패했습니다.",
    "message_verify_success": "인증 성공",
    "message_verify_fail": "인증 실패",
    "not_found_title": "찾는 페이지가 없습니다.",
    "not_found_tips": "잘못된 경로로 들어왔습니다.",
    "empty_no_data": "데이터가 없습니다.",
    "empty_on_dev": "준비 중입니다.",
    "upgrading_tip": "업데이트 중입니다. 오픈일까지 조금만 기다려 주세요.",
    "upload_avatar": "프로필 사진",
    "device_reminder_content": "현재 모바일 버전은 준비 중입니다.\n데스크탑이나 데스크탑 모드를 통해 adobaro.com으로 접속해 주세요.",
    "ready_subtitle": "升级中，请稍等",
    "platform_hao_kan": "하오칸 비디오",
    "platform_tencent": "텐센트 비디오",
    "platform_iqiyi": "아이치이",
    "platform_bilibili": "비리비리",
    "platform_xi_gua": "시과 비디오",
    "platform_wei_bo": "웨이보",
    "platform_youtube": "유튜브",
    "platform_dou_yin": "도우인",
    "platform_red_book": "샤오홍슈",
    "platform_kuai_shou": "콰이쇼우",
    "platform_cloud_music": "왕이윈뮤직",
    "platform_wechat_video": "웨이신스핀하오",
    "platform_mei_pai": "메이파이",
    "platform_da_yu": "따위하오",
    "platform_dian_tao": "디엔타오",
    "platform_qq_world": "큐큐월드",
    "platform_tiktok": "틱톡",
    "platform_instagram": "인스타그램",
    "platform_twitter": "트위터",
    "country_USA": "미국",
    "country_CAN": "캐나다",
    "country_KRW": "한국",
    "country_CHN": "중국"
  },
  "dashboard": {
    "home_page": "메인",
    "material_management": "원소스 관리",
    "content_management": "콘텐츠 관리",
    "platform_management": "플랫폼 관리",
    "subtitle_management": "자막 관리",
    "data_analysis": "데이터 분석",
    "commercial_monetization": "비즈니스 관리",
    "wallet": "내 지갑",
    "repost_management": "불펌 관리",
    "creative_laboratory": "실험실",
    "setting": "설정",
    "feedback": "피드백",
    "close_sidebar": "메뉴 접기",
    "upload_video": "업로드",
    "lang_language": "언어",
    "lang_zh_cn": "中文(简体)",
    "lang_ko_kr": "한국어",
    "lang_en_eu": "English",
    "auth": "Pass 인증센터",
    "auth_certified": "인증",
    "auth_uncertified": "미인증",
    "account_management": "다채널 관리",
    "sign_out": "로그아웃"
  },
  "sign": {
    "signin_title": "환영합니다. ^^",
    "signin_subtitle": "개통부터 수익 정산까지!\n중국 플랫폼 운영의 모든 노하우를 adobaro를 통해 만나 보세요.",
    "email_holder": "이메일을 입력해 주세요.",
    "email_error": "이메일 형식이 맞지 않습니다.",
    "signin_email_holder": "핸드폰 번호 혹은 이메일을 입력해 주세요.",
    "signin_email_error": "핸드폰 번호 혹은 이메일 형식이 맞지 않습니다.",
    "forgot_password": "비밀번호가 기억나지 않으신가요?",
    "signup": "가입",
    "signup_tip": "계정이 없다면 {0} 하기",
    "account_not_exist": "입력하신 계정 정보를 찾을 수 없습니다.다시 확인 후 시도해 주세요.",
    "signin_error": "로그인 실패",
    "google_signin_error": "구글 로그인 실패",
    "signin_warning": "해외IPㆍ보안 이슈가 감지되었습니다.가입하신 이메일에서 보안 인증을 진행해 주세요.",
    "signin_fail": "비밀번호가 맞지 않습니다.",
    "forgot_title": "비밀번호를 기억나지 않으신가요?",
    "forgot_subtitle": "가입한 이메일 주소로 인증 메일이 발송됩니다.",
    "forgot_btn": "인증 메일 발송",
    "forgot_email_send": "인증 메일이 발송되었습니다.",
    "forgot_email_send_error": "이메일 발송 실패",
    "verify_success": "인증 성공",
    "verify_fail": "인증 실패",
    "verify_success_tip": "인증이 완료되었습니다. 감사합니다.",
    "verify_fail_tip": "잘못된 링크 혹은 인증 시간이 초과되었습니다.",
    "btn_signin_retry": "다시 로그인하기",
    "account_exist": "이미 가입된 계정입니다.",
    "signup_age_14": "만 14세 이상입니다.",
    "signup_user_protocol": "adobaro 사용자 사용계약",
    "signup_privacy_protocol": "adobaro 개인정보 정책",
    "signup_marketing_protocol": "(선택) 마케팅 정보 수신 동의서",
    "signin": "로그인",
    "signin_tip": "계정이 있다면, {0} 해 주세요",
    "signup_email_send": "인증 이메일이 발송되었습니다. \n이메일 인증 후, 비밀번호 설정이 진행됩니다.",
    "signup_email_send_error": "가입 실패",
    "password_set": "신규 비밀번호 설정",
    "password_reset": "비밀번호 재설정",
    "password_set_subtitle": "이메일 인증을 통해 본인 인증이 완료되었습니다.\n안전한 비밀번호를 설정해 주세요.",
    "password_reset_subtitle": "이메일 인증을 통해 본인 인증이 완료되었습니다.\n안전한 비밀번호로 재설정해 주세요.",
    "btn_password_set": "비밀번호 설정",
    "btn_password_reset": "비밀번호 재설정",
    "password_set_success": "비밀번호가 정상적으로 설정되었습니다.",
    "password_reset_success": "비밀번호가 정상적으로 재설정 되었습니다.",
    "password_set_fail": "비밀번호 설정이 실패했습니다.",
    "password_reset_fail": "비밀번호 재설정 실패",
    "password_set_code_invalid": "인증 유효시간이 초과되었습니다.가입 절차를 다시 진행해 주세요.",
    "password_reset_code_invalid": "인증 유효시간이 초과되었습니다. 변경을 재신청해 주세요."
  },
  "auth": {
    "modal_title": "Global Pass 인증이 필요합니다.",
    "modal_subtitle": "플랫폼 개통 시에도 Global Pass 인증이 필요하지만,\n더욱 더 중요한 건, Global Pass를 인증해야,\n각 플랫폼에서의 활동으로 인한 수익이 발생된다는 사실!",
    "modal_plus_title": "Global Pass Plus 인증이 필요합니다.",
    "modal_plus_subtitle": "Global Pass Plus를 인증하시면, 아래의 기능을 사용하실 수 있습니다.",
    "modal_item1": "플랫폼 활동으로 인한 수익발생의 시작!",
    "modal_item2": "더 많은 플랫폼 개통",
    "modal_item3": "데이터 맞춤형 분석시스템 제공",
    "modal_item4": "그 밖의 다양한 기능이 제공됩니다.",
    "modal_btn_next": "다음에 하겠습니다.",
    "modal_btn_go": "Pass 인증하러 가기",
    "auth_center": "Pass 인증 센터",
    "auth_center_subtitle": "인증 정보 관리",
    "auth_global": "Global Pass",
    "auth_creator": "Global Pass Plus",
    "auth_history": "Pass 인증 사용내역",
    "auth_global_subtitle": "플랫폼 수익 개통, 채널 안전성 향상",
    "auth_creator_subtitle": "조건 달성 후 개통 가능",
    "auth_history_subtitle": "플랫폼별 pass인증 사용 히스토리",
    "auth_global_title": "Global Pass",
    "auth_creator_title": "Global Plus Pass",
    "auth_history_title": "Pass 인증 사용내역",
    "creator_auth_tip": "Global Pass를 먼저 진행해 주세요.",
    "pass_title": "Pass 인증 완료",
    "passport_title": "여권 정보",
    "platform_title": "플랫폼 정보",
    "platform_name": "플랫폼 명",
    "platform_url": "플랫폼 링크",
    "passport_dashboard_tip": "서명된 여권 스캔본",
    "passport_handhold_tip": "서명된 여권과 본인의 셀카",
    "platform_dashboard_tip": "스튜디오 대쉬보드 스크린샷",
    "platform_handhold_tip": "대쉬보드와 본인 인증 셀카",
    "btn_submit_picture": "인증을 위한 정보 제공에 동의합니다.",
    "protocol_tip": "{0} 를 충분히 숙지했고, 이에 동의하여 신청합니다.",
    "protocol_privacy": "개인 정보 보호 협의서",
    "protocol_platform": "아도바로 위임 동의서",
    "pass_no": "인증 번호",
    "pass_duration": "인증 기간",
    "pass_explain": "{0}의 여권 정보는 업로드 이후, 개인정보 보호를 위해 공개되지 않습니다.",
    "pass_in_review": "{0}이 신청되었습니다.심사를 기다려 주세요. 심사일은 업무일 기준 3~5일 정도 소요됩니다",
    "pass_in_fail": "{0} 심사가 승인되지 않았습니다. 미승인 사유:",
    "pass_expire_warning": "{0}이 {1}에 만료됩니다. 원활한 이용을 위해 재신청을 진행해 주세요.",
    "pass_expire_already": "{0}가 만료되었습니다. 원활한 이용을 위해 재신청을 진행해 주세요.",
    "pass_submit_success_tip": "{0}가 안전하게 제출되었습니다. 심사를 기다려 주세요. 심사일은 업무일 기준 3~5일 정도 소요됩니다",
    "pass_submit_fail_tip": "{0} 제출이 실패했습니다.",
    "auth_attention": "인증 시, 주의 사항",
    "global_attention_question_1": "1. 왜 여권이 필요한가요?",
    "global_attention_answer_1": "여권이 필요한 것의 대답",
    "global_attention_question_2": "2. 두번째 질문",
    "global_attention_answer_2": "두번쨰 질문에 대한 답",
    "global_attention_question_3": "3. 세번째 질문",
    "global_attention_answer_3": "세번째 질문에 대한 답",
    "global_attention_question_4": "4. 네번째 질문",
    "global_attention_answer_4": "네번째 질문에 대한 답",
    "global_attention_pass_question_1": "1. 인증 후, Global 인증을 취소하려면 어떻게 해야 하나요?",
    "global_attention_pass_answer_1": "답변",
    "global_attention_pass_question_2": "2. 두번째 질문",
    "global_attention_pass_answer_2": "답변",
    "creator_attention_question_1": "1. 왜 플랫폼 대쉬보드가 필요한가요?",
    "creator_attention_answer_1": "답변",
    "creator_attention_question_2": "2. 두번째 질문",
    "creator_attention_answer_2": "두번쨰 질문에 대한 답",
    "creator_attention_question_3": "3. 세번째 질문",
    "creator_attention_answer_3": "세번째 질문에 대한 답",
    "creator_attention_question_4": "4. 네번째 질문",
    "creator_attention_answer_4": "네번째 질문에 대한 답",
    "creator_attention_pass_question_1": "1. 인증 후, Global Pass Plus인증을 취소하려면 어떻게 해야 하나요?",
    "creator_attention_pass_answer_1": "답변",
    "creator_attention_pass_question_2": "2. 질문",
    "creator_attention_pass_answer_2": "답변",
    "attention_know_more": "더 알아보기",
    "cancel_auth": "Pass 인증 취소",
    "cancel_auth_title": "Pass 인증을 정말 취소하시겠습니까?",
    "cancel_auth_subtitle": "Pass 인증을 취소하게 되면, 모든 운영 채널의 수익이 정지되며, 개통된 채널은 모두 탈퇴처리가 됩니다. 그대로 진행하시겠습니까?",
    "cancel_auth_success": "Pass 인증 취소 성공",
    "cancel_auth_fail": "Pass 인증 취소 실패",
    "attention_approve": "위 답변에 만족하시나요?",
    "history_country": "국가",
    "history_platform": "플랫폼",
    "history_auth_type": "Pass 인증",
    "history_use_todo": "용도",
    "history_timestamp": "시간",
    "use_type_handling": "불펌처리",
    "use_type_open_income": "플랫폼 수익",
    "use_type_open_platform": "플랫폼 개통"
  },
  "home": {
    "play_more_title": "a.coin 혜택",
    "play_more_new": "신규 유저 필수 가이드",
    "play_more_email": "이메일 연동",
    "play_more_platform": "플랫폼 개통",
    "play_more_video": "콘텐츠 배포",
    "tour_upload": "콘텐츠를 업로드하여, 더 많은 구독자와 만나보세요.",
    "tour_material": "업로드된 콘텐츠 원소스를 관리할 수 있습니다.",
    "tour_content": "플랫폼에 배포된 콘텐츠를 관리할 수 있습니다.",
    "tour_platform": "개통된 플랫폼 및 개통할 수 있는 플랫폼을 관리할 수 있습니다.",
    "tour_coin": "a.coin 충전 및 획득/소모 히스토리를 관리할 수 있습니다.",
    "tour_account": "계정 및 프로필을 관리할 수 있습니다.",
    "unlock_describe": "완료하면 {0} a.coin을 획득할 수 있어요.",
    "play_more_done": "획득 완료",
    "play_more_unlock": "획득하기",
    "read_more": "더보기",
    "all_platform_statistic": "전체 플랫폼 데이터 통계",
    "statistic_date": "통계 데이터 {0}",
    "statistic_platform": "개통 플랫폼",
    "all_platform_fans": "전체 플랫폼 구독자수",
    "all_platform_plays": "전체 플랫폼 조회수",
    "latest_content": "최신 콘텐츠",
    "latest_popular": "내 인기 플랫폼",
    "all_coin": "a.coin",
    "coin_rule": "a.coin 이란?",
    "read_details": "상세 내역",
    "coin": "a.coin",
    "platform_news": "플랫폼 뉴스",
    "news_content": "adobaro에 오신걸 환영합니다!!\n플랫폼 뉴스에선 아도바로에 관한 이야기와 신규 기능들을 소개해드립니다, 앞으로 많은 관심 부탁드립니다.",
    "news_help_btn": "도움 센터 살펴보기",
    "unlocked_get_coin": "완료하면 {0} a.coin을 획득할 수 있어요.",
    "unlocked_task_card": "임무 카드 보러 가기",
    "unlocked_to_content": "콘텐츠 관리",
    "unlocked_to_upload": "콘텐츠 업로드",
    "unlocked_to_open": "플랫폼 개통",
    "unlocked_upload_tips": "플랫폼을 개통하여 아도바로를 최대한 활용해 보세요."
  },
  "upload": {
    "file_btn_status_removed": "업로드",
    "file_btn_status_uploading": "업로드 중",
    "file_btn_status_done": "파일 변경",
    "file_btn_status_error": "재업로드",
    "file_dragger_subtitle": "업로드 또는 파일을 여기로 끌어 놓으세요.",
    "file_dragger_tip": "{0} 콘텐츠 업로드가 가능합니다.",
    "file_status_uploading": "업로드 중:",
    "file_status_done": "완료",
    "file_status_error": "업로드 실패",
    "file_type_error": "파일 형식은 {0} 만 지원됩니다.",
    "file_limit_size": "파일 크기는 {0} MB를 넘어선 안 됩니다.",
    "file_limit_size_tip": "({0} Mb 이내)",
    "file_change_tip": "파일 변경",
    "file_upload_image_tip": "썸네일 업로드",
    "file_change_image_tip": "썸네일 변경",
    "file_name_already_exist": "중복된 파일명이 존재합니다.파일명을 변경하거나 다시 업로드해 주세요.",
    "file_size_exceeds_capacity": "스토리지 용량이 가득 찼습니다.잔여 용량을 확인해 주세요.",
    "example_pic_tip": "예시",
    "guide_tips": "{0} 만 준비하시면 됩니다.",
    "guide_tip_content": "오리지널 영상, 썸네일, 제목, 그리고 소개",
    "guide_subtitle": "adoba AI가 제목, 소개글을 현지 언어에 맞게 보정해 주며, 각각의 플랫폼에 맞는 썸네일을 자동 생성하여, 최적화된 콘텐츠로 업로드됩니다.",
    "guide_upload_btn": "내 콘텐츠 업로드 하기",
    "page_title": "업로드 설정",
    "page_subtitle": "콘텐츠가 개통한 플랫폼에 업로드 됩니다.",
    "upload_file_holder": "파일을 업로드해 주세요.",
    "upload_file_complete_tip": "파일 업로드가 완료된 후 진행해 주세요.",
    "select_platform": "업로드 플랫폼 선택",
    "select_platform_holder": "업로드할 플랫폼을 선택해 주세요.",
    "open_more_platform": "더 많은 플랫폼 확장",
    "basic_info": "기본 설정",
    "basic_info_tip": "adoba AI가 각각의 플랫폼에 최적화된 컨텐츠로 자동 변환되어 업로드됩니다.",
    "basic_content_title": "콘텐츠 제목",
    "basic_content_title_holder": "제목을 입력해 주세요.",
    "basic_content_cover": "콘텐츠 썸네일",
    "basic_content_cover_holder": "썸네일을 업로드해 주세요.",
    "basic_content_cover_upload": "{0} 형식만 업로드가 가능합니다.",
    "basic_content_cover_upload_tip": "썸네일 이미지에 글자가 포함되어 있지 않아야 합니다.",
    "basic_content_cover_change_tip": "썸네일 변경",
    "basic_content_intro": "콘텐츠 소개",
    "basic_content_intro_holder": "소개를 입력해 주세요.",
    "basic_content_subtitle": "자막 선택",
    "basic_content_subtitle_holder": "자막을 선택해 주세요.",
    "basic_content_timer_release": "예약 업로드",
    "basic_content_timer_release_tip": "{0} 시간 이후부터 설정이 가능합니다.",
    "btn_release": "업로드",
    "btn_draft": "임시 저장",
    "draft_title": "임시 저장된 콘텐츠",
    "draft_subtitle": "업로드가 완료되지 않은 임시 저장 콘텐츠가 있습니다.\n계속 진행하시겠습니까?",
    "draft_read_image_error": "이미지 로딩에 실패했습니다. 다시 업로드해 주세요.",
    "draft_save_tips": "업로드 완료 후 임시 저장이 가능합니다.",
    "draft_save_success": "임시 저장 성공",
    "release_content_success": "곧 업로드된 콘텐츠의 심사가 시작됩니다.\n심사 통과 후, 콘텐츠 관리에서 확인 가능합니다.",
    "release_content_fail": "업로드에 실패했습니다.",
    "release_free_count": "이번 주 남은 무료 업로드 : {0}회",
    "release_limit_tip_1": "1. 콘텐츠 업로드는 {0} a.coin이 필요합니다.",
    "release_limit_tip_2": "2. 주 1회 무료 업로드가 가능합니다.",
    "release_limit_tip_3": "3. Global Pass 인증 유저는 주 3회 무료 업로드가 가능합니다.",
    "release_limit_tip_4": "4. Global Pass Plus 인증 유저는 주 5회 무료 업로드가 가능합니다."
  },
  "material": {
    "page_title": "원소스 관리",
    "page_subtitle": "업로드된 콘텐츠를 손쉽게 관리하고, 다른 플랫폼으로 한 번에 배포할 수 있습니다.",
    "sider_my_file": "내 문서",
    "sider_enlarge_capacity": "클라우드 용량",
    "menu_video": "영상",
    "menu_images": "사진",
    "menu_sounds": "효과음",
    "menu_subtitles": "자막",
    "menu_fonts": "폰트",
    "menu_bgm": "BGM",
    "menu_backup_space": "백업 클라우드",
    "alert_capacity": "용량을 확장하여 아도바로 기능을 최대한 활용해 보세요.",
    "alert_upgrade": "용량 확장",
    "all_backups": "선택 백업",
    "all_delete": "선택 삭제",
    "all_download": "선택 다운로드",
    "confirm_title_delete": "정말 삭제하시겠습니까?",
    "confirm_ok": "삭제",
    "confirm_cancel": "취소",
    "selected_items": "{0}개의 파일이 선택됨",
    "backup_btn": "백업",
    "delete_btn": "삭제",
    "download_btn": "다운로드",
    "pagination_total": "총 {0} 개",
    "video_title": "전체 영상",
    "video_delete_confirm": "{0}개의 영상을 삭제하시겠습니까?",
    "video_deleted_description": "삭제된 영상은 신규 플랫폼에 2차 배포가 불가합니다.",
    "video_name": "파일명",
    "video_size": "사이즈",
    "video_published_platform": "배포 플랫폼",
    "video_opened_platform": "개통 플랫폼",
    "image_title": "전체 사진",
    "subtitles_title": "전체 자막",
    "subtitles_delete_confirm": "정말 {0}개의 파일을 삭제하시겠습니까?",
    "subtitles_deleted_description": "삭제된 파일은 신규 플랫폼에 2차 배포가 불가합니다.",
    "subtitles_name": "파일명",
    "subtitles_size": "사이즈",
    "subtitles_lang": "언어",
    "modal_title": "이름 변경",
    "modal_name_label": "이름",
    "modal_name_holder": "이름을 입력해 주세요."
  },
  "content": {
    "page_title": "콘텐츠 관리",
    "page_subtitle": "각 플랫폼에 업로드된 콘텐츠를 관리합니다.",
    "radio_all": "전체",
    "radio_play": "높은 조회수 순",
    "radio_like": "높은 좋아요 순",
    "page_details_title": "콘텐츠 상세보기",
    "base_info": "기본 정보",
    "base_video": "영상",
    "base_cover": "썸네일",
    "base_title": "제목",
    "base_subtitle": "자막",
    "base_upload_date": "업로드시간",
    "empty_cover": "썸네일이 없네요",
    "distributed_platform": "업로드된 플랫폼",
    "distributed_name": "플랫폼",
    "distributed_state": "상태",
    "distributed_subtitle": "자막",
    "distributed_plays": "조회 수",
    "distributed_likes": "좋아요 수",
    "distributed_upload_date": "업로드 시간",
    "distributed_action": "집행",
    "status_playing": "업로드 완료",
    "status_opening": "업로드 중",
    "status_failure": "업로드 실패",
    "status_unpublished": "미배포",
    "status_not_opened": "미개통",
    "action_operate": "집행",
    "action_ask": "문의",
    "action_publish": "한번에 업로드",
    "action_open": "개통하기",
    "action_operate_check": "보기",
    "action_operate_analysis": "분석",
    "action_operate_edit": "수정",
    "action_operate_delete": "삭제",
    "republish_platform_success": "성공적으로 업로드 되었습니다.",
    "republish_platform_error": "업로드에 실패했습니다.",
    "video_url_error": "업로드 링크를 찾지 못 했습니다."
  },
  "platform": {
    "guide_title": "운영하는 플랫폼이 있나요?",
    "guide_subtitle": "운영하는 플랫폼이 있다면, 아래에 입력해 주세요.\n최적의 중국 플랫폼을 추천해 드립니다.",
    "guide_platform_holder": "플랫폼을 선택해 주세요.",
    "guide_platform_url_holder": "채널 ID를 입력해 주세요.",
    "guide_platform_url_error": "채널 ID를 다시 확인해 주세요.",
    "guide_platform_already": "이미 등록된 플랫폼 채널입니다.",
    "guide_platform_confirm": "채널 정보를 제출하면, 당분간 수정이 불가능합니다.\n입력된 정보가 맞다면 \"확인\" 을 눌러주세요",
    "guide_platform_tip": "@채널 아이디는 어디서 볼 수 있나요?",
    "guide_btn_recommend": "플랫폼 추천",
    "guide_platform_error": "플랫폼 추천 실패",
    "guide_skip_title": "나중에 하기",
    "guide_skip_subtitle": "나중에 하시려는 이유가 무엇인지 알려 주세요.",
    "guide_skip_holder": "이유를 선택해 주세요.",
    "guide_skip_reason_1": "운영 채널이 없습니다.",
    "guide_skip_reason_2": "이미 개통한 채널이 있습니다.",
    "guide_skip_reason_3": "원하는 국가 플랫폼이 없습니다.",
    "guide_skip_reason_4": "기타 이유",
    "guide_skip_btn": "메인으로 가기",
    "guide_analysis_title": "추천 분석 중",
    "guide_analysis_subtitle": "완료 후 자동으로 화면이 이동됩니다.",
    "guide_analysis_fail_title": "현재 이용자가 많습니다.",
    "guide_analysis_fail_subtitle": "분석이 완료된 후 이메일로 알려 드릴께요.",
    "guide_analysis_fail_btn_next": "다음에 할께요.",
    "guide_analysis_fail_btn_confirm": "알려 주세요.",
    "guide_analysis_done_title": "플랫폼 분석 완료",
    "guide_analysis_done_subtitle": "운영 중인 플랫폼을 분석하여 최적의 중국 플랫폼을 추천 드렸어요.",
    "guide_analysis_platform_about": "플랫폼 이해돕기",
    "guide_analysis_btn_open": "플랫폼 개통하기",
    "guide_analysis_btn_already": "이미 채널이 있으세요?",
    "guide_analysis_btn_next": "다음에 할게요",
    "guide_recommend_fail": "플랫폼 개통 실패",
    "guide_bind_title": "채널 연동",
    "guide_bind_subtitle": "이미 개통한 채널이 있다면, 아도바로에 연동해 보세요~\n모든 플랫폼을 한 번에 관리하실 수 있습니다.",
    "guide_bind_tip": "왜 비밀번호를 입력해야 하나요?",
    "guide_bind_btn_confirm": "채널 연동하기",
    "guide_bind_btn_next": "다음에 연동할게요.",
    "guide_bind_success": "플랫폼 개통이 신청되었습니다. 승인 여부는 이메일을 통해 안내되오니, 메일을 확인해 주세요.",
    "guide_open_phone_title": "플랫폼 가입",
    "guide_open_phone_subtitle": "중국 플랫폼 가입을 위해 문자 수신이 가능한 핸드폰 번호가 필요합니다.",
    "guide_open_btn_verify": "본인 인증",
    "guide_open_submit_success": "본인 인증을 위해 이메일이 발송되었습니다.",
    "guide_open_send_sms_error": "인증 발송 실패",
    "guide_open_verify_title": "인증번호 발송",
    "guide_open_verify_subtitle": "해당 플랫폼의 인증 문자가 곧 발송됩니다.\n아래 ****** 에 해당하는 인증번호를 입력해 주세요.",
    "guide_open_btn_verifying": "인증하기",
    "guide_open_verify_success": "인증 확인 중입니다. 잠시만 기다려 주세요.",
    "guide_open_verify_error": "인증에 실패했습니다.",
    "manage_title": "플랫폼 관리",
    "manage_subtitle": "여기에서는 개통된 플랫폼과 개통할 수 있는 플랫폼들을 관리하실 수 있습니다.",
    "applied_platform": "내 플랫폼",
    "btn_refresh": "데이터 갱신",
    "btn_refresh_tip": "클릭시 플랫폼 상황이 갱신됩니다",
    "btn_all": "전체",
    "btn_play_most": "높은 조회수 순",
    "btn_like_most": "높은 좋아요 순",
    "platform_empty": "더 많은 플랫폼들을 만나 보세요.",
    "status_done": "개통 완료",
    "status_opening": "개통 중입니다. 잠시만 기다려 주세요.",
    "status_verifying": "인증 중입니다. 잠시만 기다려 주세요.",
    "status_verify_failed": "인증 실패",
    "status_failed": "개통 실패",
    "btn_status_done": "업로드",
    "btn_status_verify": "인증번호 발송",
    "btn_status_failed": "자세히 보기",
    "un_apply_platform": "더 많은 플랫폼",
    "open_count": "{0} 명의 유저가 이미 개통했습니다.",
    "lastest_open": "유저 {0} {1} 개통",
    "btn_open": "개통하기",
    "btn_reapply": "재신청",
    "open_platform_title": "플랫폼 개통",
    "open_platform_subtitle": "복잡하고 번거로웠던 중국 플랫폼 개통을 간단하고 안전하게 개통해 드립니다.",
    "details_title": "플랫폼 요약",
    "details_info_title": "기본 정보",
    "details_info_avatar": "프로필 사진",
    "details_info_intro": "채널 소개",
    "details_info_intro_empty": "채널 소개가 없습니다.",
    "details_info_intro_holder": "채널 소개를 입력해 주세요.",
    "details_info_date": "채널 가입일",
    "details_info_date_empty": "데이터가 없습니다.",
    "details_info_date_holder": "채널 가입 시간을 선택해 주세요.",
    "details_info_classify": "채널 카테고리",
    "details_info_classify_empty": "데이터가 없습니다.",
    "details_info_classify_holder": "카테고리를 선택해 주세요.",
    "details_info_income": "플랫품 수익",
    "details_info_order": "광고",
    "details_info_btn_again": "재추천",
    "details_info_btn_open": "바로 개통하기",
    "details_info_btn_edit": "기본 정보 수정",
    "details_info_btn_submit": "저장 및 수정",
    "details_data_overview_title": "데이터 요약",
    "details_data_radio_yesterday": "어제",
    "details_data_radio_nearly7": "최근 7일",
    "details_data_radio_nearly30": "최근 30일",
    "details_data_fans": "누적 구독자 수",
    "details_data_likes": "누적 좋아요 수",
    "details_data_platform": "누적 플랫폼 수익",
    "details_data_published": "누적 배포 수",
    "details_data_plays": "누적 조회 수",
    "details_data_ad": "누적 광고 수익",
    "details_release_video_title": "배포된 콘텐츠"
  },
  "wallet": {
    "title": "My wallet",
    "subtitle": "a.coin은 adobaro에서 사용되는 플랫폼 가상화폐이며, 일정 금액이 쌓이면 출금이 가능합니다.",
    "overview_title": "현재 a.coin",
    "overview_statistic_title": "a.coin",
    "overview_btn_deposit": "충전",
    "overview_btn_withdrawal": "출금신청",
    "record_title": "a.coin 기록",
    "record_enter": "입금 기록",
    "record_out": "출금 기록",
    "enter_date": "입금 시간",
    "enter_content": "내역",
    "enter_all": "전체",
    "out_date": "출금 시간",
    "out_content": "내역",
    "out_all": "전체",
    "coin_unit": "a.coin",
    "scene_init_account": "가입 기념",
    "scene_open_platform": "플랫폼 개통",
    "scene_bind_platform": "플랫폼 연동",
    "scene_new_video": "콘텐츠 업로드",
    "scene_push_video": "플랫폼 업로드",
    "scene_withdrawal": "정산",
    "scene_recharge": "충전",
    "scene_platform_settlement": "플랫폼 수익",
    "scene_gift": "사전예약 혜택",
    "get_tips": "내 지갑에 코인이 도착했어요.",
    "get_rule_title": "a.coin 소개",
    "get_rule_content": "채널의 성장을 위해 사용 가능하며, 정산 신청을 통해 수익화도 가능한 아도바로 내 통용되는 코인입니다",
    "cost_use_btn": "사용하기",
    "cost_insufficient_title": "죄송합니다",
    "cont_insufficient_subtitle": "a.coin 이 부족하네요",
    "cost_insufficient_tips": "{0} a.coin이 필요한데, 현재 {1} a.coin을 가지고 계세요"
  },
  "setting": {
    "title": "설정",
    "subtitle": "adobaro 이용 관련 설정을 할 수 있습니다.",
    "user_info": "유저 정보",
    "account_security": "계정 보안",
    "notification": "알림",
    "protocol": "약관",
    "user_uid": "UID",
    "user_phone": "핸드폰 번호",
    "user_email": "이메일",
    "user_password": "비밀번호",
    "user_third_party_login": "서드파티 로그인",
    "user_not_set": "미설정",
    "user_set_password": "비밀번호 수정",
    "user_to_change": "변경",
    "user_bound": "연동됨",
    "user_to_bound": "연동하기",
    "user_bound_google": "구글 계정에 연동됨",
    "user_unbound_google": "구글 계정에 연동하기",
    "user_problem_center": "문의 센터",
    "user_phone_title_bind": "핸드폰 번호를 입력해 주세요.",
    "user_phone_subtitle_bind": "계정 보안을 위해 핸드폰 인증 후 진행이 가능합니다.",
    "user_phone_title_new": "신규 핸드폰 번호를 입력해 주세요.",
    "user_login_country_check": "국가를 선택해 주세요.",
    "user_modify_phone_verify_fail": "핸드폰 인증 실패",
    "user_support_email": "고객센터 이메일",
    "user_code_not_received_tip": "인증번호가 오지 않는 경우 고객센터로 문의해 주세요.",
    "user_code_not_received": "인증번호를 못 받으셨나요?",
    "user_verify_header": "신분 인증",
    "user_modify_phone_header": "핸드폰 번호 변경",
    "user_modify_email_update_button": "이메일 변경",
    "user_modify_email_explanation": "계정 보안을 위해 이메일 인증 후 진행이 가능합니다.",
    "user_modify_email_old_holder": "현재 이메일을 입력해 주세요.",
    "user_modify_email_old_popover": "이메일이 기억나지 않으시면, 고객센터로 문의 주시기 바랍니다.",
    "user_modify_email_forgot": "이메일이 기억나지 않으시나요?",
    "user_modify_email_verify_fail": "이메일 인증 실패",
    "user_modify_new_email": "신규 이메일",
    "user_modify_email_new_holder": "신규 이메일을 입력해 주세요.",
    "user_modify_email_title": "이메일 변경",
    "user_modify_password_change_button": "비밀번호 변경",
    "user_modify_password_title": "비밀번호 변경",
    "user_modify_password_explanation": "계정 보안을 위해 이메일 인증번호 확인 후 진행이 가능합니다.",
    "user_modify_new_password": "신규 비밀번호",
    "user_modify_password_new_holder": "신규 비밀번호를 입력해 주세요.",
    "user_modify_password_confirm_holder": "신규 비밀번호를 재입력해 주세요.",
    "user_modify_password_head": "비밀번호 변경",
    "security_signin": "로그인 보안",
    "security_credit": "신용도",
    "security_signin_date": "로그인 시간",
    "security_signin_mode": "로그인 방식",
    "security_signin_third": "서드파티 로그인",
    "security_signin_google": "구글 로그인",
    "security_signin_account": "로그인 계정",
    "security_signin_ip": "IP주소",
    "security_signin_country": "국가",
    "security_signin_action": "집행",
    "delete_confirm_title": "정말 삭제하시겠습니까?",
    "credit_action_date": "집행시간",
    "credit_action_behavior": "행위",
    "credit_ score": "신용도",
    "notice_spread": "마케팅 알림",
    "notice_platform": "플랫폼 알림",
    "notice_sms": "문자 알림",
    "notice_email": "이메일 알림",
    "notice_adobaro": "adobaro",
    "notice_operate": "운영 플랫폼",
    "notice_select_holder": "선택해 주세요.",
    "notice_agree": "동의",
    "notice_disagree": "미동의",
    "user_problem_question_1": "1. 질문",
    "user_problem_answer_1": "답변",
    "user_problem_question_2": "2. 질문",
    "user_problem_answer_2": "답변",
    "user_problem_question_3": "3. 질문",
    "user_problem_answer_3": "답변",
    "user_problem_question_4": "4. 질문",
    "user_problem_answer_4": "답변",
    "protocol_user": "adobaro 사용자 사용계약",
    "protocol_privacy": "adobaro 개인정보 정책",
    "protocol_marketing": "마케팅 정보 수신 동의서"
  },
  "email": {
    "1": "제목과 소개 글 심사 관련",
    "2": "민감한 혹은 불량한 내용이 감지되었습니다.",
    "3": "콘텐츠 내용과 맞지 않습니다.",
    "4": "중국 법률에 위반되는 내용이 감지되었습니다.",
    "6": "썸네일 심사 관련",
    "7": "썸네일에 위반 내용이 포함되어 있습니다. 생식기나 민감한 부위가 노출되거나, 불쾌감을 주는 이미지이거나, 저속한 내용, 욕설, 공공질서와 반도덕적 내용이 포함되어 있을 수 있습니다. ",
    "9": "썸네일이 심하게 늘어나거나 화면이 깨지거나 밝기가 너무 낮거나 지나치게 노출되었습니다.",
    "10": "썸네일의 해상도가 너무 낮습니다.",
    "11": "썸네일와 비디오 내용이 일치하지 않습니다.",
    "13": "배경이 제거됐거나, 인물이 일치하지 않거나, 저품질 이미지이기 때문에 거부되었습니다. ",
    "14": "썸네일에 외부 콘텐츠나 정보가 포함되어 있습니다.",
    "15": "콘텐츠 심사 관련",
    "16": "콘텐츠의 품질 문제가 있습니다.",
    "17": "비디오의 해상도가 낮고 화면이 깨지며 밝기, 대비, 해상도, 화면이 흐릿하여 주체를 구분할 수 없습니다.",
    "18": "비디오에 잘못된 링크, 404 오류, 6초 미만의 길이가 있습니다.",
    "19": "비디오 콘텐츠에 명확한 스토리라인이 없습니다. ",
    "20": "비디오 콘텐츠에 아무런 내용이 없습니다. ",
    "21": "비디오의 재생이 원활하지 않고 버벅입니다.",
    "22": "화면이 확연하게 흔들리거나 5초 이상 계속 흔들리며 3초 이상 지속적으로 번쩍입니다. 정상적인 시청에 영향을 미칩니다.",
    "23": "화면의 주체가 완전하지 않고 지속적으로 (≥3초) 절단되었으며, 심하게 늘어나거나 압축되었으며, 양쪽에 검은 테두리가 있거나 검은 테두리로 채워져 있습니다.",
    "24": "콘텐츠에 오디오가 없어 거부되었습니다.",
    "25": "오프닝과 엔딩의 검은 화면이 3초를 초과하지 않아야 합니다.",
    "26": "이미지 품질이 낮습니다.",
    "27": "비디오 콘텐츠가 법적 문제를 일으킬 수 있습니다.",
    "28": "비디오 콘텐츠가 중국의 국가 지도자, 공격범, 검사, 국가 기관, 국기 등 이미지나 단어를 모욕하는 것으로 여겨져 중국 법을 위반할 우려가 있습니다.",
    "29": "비디오 콘텐츠에 불법이거나 위반적이며 저속한 성적, 피, 공포 관련 요소가 있습니다.",
    "30": "비디오 콘텐츠에 사이비 종교, 편가르기, 반동 조직 등과 관련된 요소가 있습니다.",
    "31": "비디오 콘텐츠가 미성년자나 장애인의 신체적 및 정신적 건강에 해를 끼칠 수 있는 내용이 있습니다.",
    "32": "미성년자는 대표자로서 상업적 마케팅 콘텐츠를 촬영할 수 없습니다.",
    "33": "비디오 콘텐츠가 공공질서, 사회적 가치관과 관련된 요소를 위반합니다. 외도, 가정 폭력, 과시, 차별, 선전, 흡연, 저속한 언어, 장난, 악동, 학대, 음주, 문신 등이 포함됩니다.",
    "34": "비디오 콘텐츠에 성적 암시 행동, 저속한 장면, 저속한 춤, 부적절한 남녀 관계 등이 있습니다.",
    "35": "비디오 콘텐츠에 심각한 노출이 포함되어 있습니다. ",
    "36": "비디오의 음질에  문제가 있습니다.",
    "37": "갑자기 음소거되거나 소리가 사라지거나 오디오 트랙이 일관성 없이 겹쳐지거나 관련성이 없으며 음성과 영상이 심각하게 관련이 없습니다. ",
    "38": "심한 잡음이 존재합니다.",
    "39": "자막의 품질 문제가 있습니다.",
    "40": "자막이 불명확하거나 깨져 있거나 중첩되거나 문장이 순서대로 전달되지 않습니다.",
    "41": "외국어 비디오에 중국어 자막이 없거나 자막의 품질 문제로 인해 추천되지 않습니다.",
    "42": "자막이 영상이나 오디오와 싱크되지 않거나 관련성이 없습니다.",
    "43": "잘린 자막이 존재합니다.",
    "44": "자막이 인물의 얼굴을 대부분 가리고 있습니다.",
    "signup_title": "환영합니다~",
    "signup_subtitle": "아도바로 가입 절차입니다.",
    "signup_set_password_tip": "계정 보안을 위해 이메일 인증 후 비밀번호를 설정할 수 있습니다.",
    "signup_set_password": "비밀번호 설정",
    "sigin_warning_title": "보안 이슈",
    "sigin_warning_subtitle": "해외 IP로 접속이 감지되었습니다.",
    "sigin_warning_midtitle": "계정 보안을 위해 아래 로그인 인증을 진행해 주세요.",
    "sigin_warning_verify": "로그인 인증",
    "sigin_warning_not_myself": "만약 본인이 로그인한게 아니라면",
    "sigin_warning_change_pwd": "비밀번호를 지금 즉시 변경해 주세요.",
    "sigin_warning_pwd": "비밀번호 변경",
    "reset_pwd_title": "비밀번호 재설정",
    "reset_pwd_subtitle": "비밀번호 재설정 요청을 받았습니다.",
    "reset_pwd_midtitle": "계정 보안을 위해 이메일 인증 후 비밀번호를 재설정할 수 있습니다.",
    "reset_pwd_set": "비밀번호 설정",
    "release_title": "업로드 현황",
    "release_audit_fail": "심사 반려",
    "release_upload_video": "업로드한 콘텐츠가",
    "release_audit_reason": "심사에 반려되었습니다. 반려 사유는 아래와 같습니다.",
    "release_re_upload": "다시 업로드",
    "release_success": "업로드 완료",
    "release_up_video": "업로드한 콘텐츠가",
    "release_up_platform": "{{.Platform}} 플랫폼에 업로드 되었습니다.",
    "release_view_data": "콘텐츠 데이터",
    "op_notice_title": "플랫폼 개통 현황",
    "op_notice_fail": "플랫폼 개통 실패",
    "op_notice_fail_platform": "신청하신 {{.Platform}} 플랫폼 개통이 반려되었습니다.",
    "op_notice_fail_reason": "반려 사유",
    "op_notice_success": "플랫폼 개통 성공",
    "op_notice_success_platform": "신청하신 {{.Platform}} 플랫폼이 개통 완료되었습니다.",
    "op_notice_ai": "adoba AI를 통해 적합한 채널명과 채널 소개가 플랫폼에 최적화되어 적용되었습니다.",
    "op_notice_view_data": "플랫폼 정보 보기",
    "certification_title": "Pass 인증 통지",
    "certification_subtitle_global_fail": "Global Pass 심사 반려",
    "certification_global_fail": "신청하신 Global Pass 인증 심사가 반려되었습니다.",
    "certification_subtitle_creator_fail": "Creator Pass 심사 반려",
    "certification_creator_fail": "신청하신 Creator Pass 인증 심사가 반려되었습니다.",
    "certification_view_reason": "반려 사유",
    "certification_in_applying": "인증 심사가 통과되면, 다음 기능들을 활용하실 수 있습니다.",
    "certification_global_success": "Global Pass 인증 완료",
    "certification_subtitle_global_success": "신청하신 Global Pass 인증이 완료되었습니다.",
    "certification_open_now": "지금부터 다음 기능들을 활용하여 더 다양한 크리에이터 활동이 가능합니다.",
    "certification_subtitle_creator_success": "Creator Pass 인증 완료",
    "certification_creator_success": "신청하신 Creator Pass 인증이 완료되었습니다.",
    "certification_earnings": "더 많은 콘텐츠 수익 창출",
    "certification_auth_cancel": "Pass 인증 취소 통지",
    "certification_cancel_start": "{{开始时间}} 신청하신 Pass 인증 취소가 완료되었습니다.",
    "certification_get_a_coin": "신청일로부터 플랫폼 수익은 중지되지만, 아도바로 내 a.coin 은 사용 가능합니다.",
    "certification_a_coin": "a.coin 알아보기",
    "certification_re_register": "다시 플랫폼 개통 신청을 원한다면",
    "certification_cancel_end": "{{结束时间}} 이후에 플랫폼 재개통이 가능합니다.",
    "certification_thank": "이용해 주셔서 감사합니다.",
    "captcha_notice_title": "인증 번호 통지",
    "captcha_notice_subtitle": "인증번호 확인",
    "captcha_notice_hello": "안녕하세요,",
    "captcha_notice_platform": "플랫폼",
    "captcha_notice_send_code": "의 인증번호가 곧 발송됩니다.",
    "captcha_notice_platform_secure": "플랫폼 보안 문제로 핸드폰 인증번호가 꼭 필요합니다.",
    "captcha_notice_sgin": "수신 문자함을 확인해 주세요. <br>\n만약 이미 adobaro 내에서 인증번호를 처리하였다면, 본 메일을 무시하셔도 됩니다.",
    "captcha_notice_fill_code": "인증번호 발송",
    "captcha_notice_not_myself": "만약, 본인이 신청한 것이 아니라면",
    "captcha_notice_handle": "아도바로 고객센터로 문의해 주시기 바랍니다.",
    "captcha_notice_foot": "본 이메일은 답변을 지원하지 않습니다.",
    "verify_code_title": "인증번호 알림",
    "verify_code_subtitle": "인증번호를 확인해 주세요.",
    "verify_code_hello": "안녕하세요,",
    "verify_code_platform": "플랫폼",
    "verify_code_send_code": "아래 버튼 선택 시 인증번호가 발송됩니다.",
    "verify_code_resend": "인증번호를 다시 확인해 주세요.",
    "verify_code_security": "플랫폼 보안 문제로 핸드폰 인증번호가 꼭 필요합니다.",
    "verify_code_sign": "유의해 주시기 바랍니다.",
    "verify_code_fill_code": "인증번호 입력",
    "email_subject_sign_account": "[adobaro] 가입을 환영합니다.",
    "email_subject_reset_pwd": "[adobaro] 비밀번호 변경 요청이 도착했습니다.",
    "email_subject_sign_warning": "[adobaro] 로그인 보안 이슈가 발견되었습니다.",
    "email_subject_auth": "[adobaro] Pass 인증 신청하셨나요?",
    "email_subject_release": "[adobaro] 업로드한 콘텐츠 진행사항입니다.",
    "email_subject_verify_code": "[adobaro] 보안을 위한 인증번호 요청입니다.",
    "email_subject_op_result": "[adobaro] 개통 신청하신 플랫폼 진행 결과입니다.",
    "email_snject_verify_email": "[adobaro] 인증을 위한 이메일입니다.",
    "email_verify_code_subtitle": "본인 인증을 위한 과정입니다.",
    "email_verify_code_hello": "안녕하세요. 본인 확인을 위해 인증번호가 발송됩니다.",
    "email_verify_code_input": "아래로 입장 후 인증을 진행해 주세요.",
    "email_verify_code_not_myself": "만약, 본인이 아니라면 로그인 후 이메일을 변경해 주세요.",
    "email_verify_code_handle": "인증번호는 고객센터에서도 요청하지 않으니, 타인에게 알려주거나, 유출되지 않도록 주의해 주시기 바랍니다.",
    "email_verify_code_recover": "본 이메일은 회신이 불가능합니다.",
    "email_verify_code_warning": "문제가 있으시면, 고객센터로 문의해 주세요.",
    "auth_err_1": "여권 사진 확인이 어렵습니다. 재확인하시고 업로드해주세요",
    "auth_err_2": "본인과 여권 사진의 대조 확인이 어렵습니다. 다시 확인 후 업로드해 주세요.",
    "auth_err_3": "여권 유효기간이 지났습니다. 업데이트해 주세요.",
    "auth_err_4": "여권 정보가 일치하지 않습니다. 정보를 정확히 입력해주세요",
    "auth_err_5": "동일한 여권 정보가 존재합니다.",
    "auth_err_6": "스크린샷이 선명하지 않습니다. 다시 확인 후 업로드해 주세요.",
    "auth_err_7": "본인과  스크린샷의 대조 확인이 어렵습니다. 다시 확인 후 업로드해 주세요.",
    "auth_err_8": "이미지에서 추출된 정보와 기입하신 정보가 일치하지 않습니다. 다시 확인 후 업로드해 주세요.",
    "auth_err_9": "동일한 대쉬보드 정보가 존재합니다.",
    "5 (这个不会存在）": "글자 수를 초과하였습니다.",
    "8 （不存在）": "썸네일에 테두리가 있습니다.",
    "12 (不存在)": "썸네일의 텍스트가 잘립니다."
  }
};export default ko