const en = {
  "global": {
    "language": "English",
    "customer_service": "Customer Service",
    "customer_service_tips": "Please contact {0} for any inconveniences.",
    "customer_service_subtitle": "If you are experiencing any inconvenience, please contact us via email.",
    "customer_service_email": "Customer service e-mail",
    "email_holder": "Enter e-mail address",
    "email_error": "Incorrect e-mail format",
    "password_holder": "Enter password",
    "password_error": "8 to 20 digits, must contain at least one uppercase and lowercase letter, special character, and number.",
    "password_confirm_error": "Incorrect password",
    "phone_holder": "Enter mobile phone number",
    "code_holder": "Enter verification code",
    "code_error": "Incorrect verification code format",
    "code_get": "Send",
    "code_resend": "Send again",
    "agreement_all_agree": "Agree to all",
    "agreement_age_14": "I am over 14 years old.",
    "agreement_age_18": "I am over 18 years old.",
    "protocol_checkbox_user": "{0} User Agreement",
    "protocol_checkbox_privacy": "The Privacy Policy of {0}",
    "agreement_message_tip": "You must agree to the agreements to proceed.",
    "btn_add": "Add",
    "btn_change": "Edit",
    "btn_delete": "Delete",
    "btn_confirm": "Confirm",
    "btn_consider": "Let me think",
    "btn_close": "Close",
    "btn_submit": "Submit",
    "btn_cancel": "Cancel",
    "btn_know": "Got it",
    "btn_last_step": "Previous",
    "btn_next_step": "Next",
    "btn_agree": "Agree",
    "btn_disagree": "Disagree",
    "btn_go": "Shortcut",
    "btn_signin": "Log in",
    "btn_google_signin": "Log in with Google",
    "btn_signup": "Sign up",
    "btn_send": "Send",
    "btn_verify": "Verification",
    "btn_done": "Completed",
    "btn_save": "Save",
    "btn_put_away": "Fold",
    "btn_expand": "See more",
    "btn_edit": "Edit",
    "modal_open_platform_title": "Still haven't opened yet?",
    "modal_open_platform_subtitle": "Meet more global subscribers by opening platforms",
    "modal_open_platform_btn": "Apply for platform opening",
    "total_count": "{0} data",
    "list_no_more": "All data have been checked.",
    "message_success_create": "Successfully added",
    "message_error_create": "Failed to add",
    "message_success_update": "Successfully edited",
    "message_error_update": "Failed to edit",
    "message_success_delete": "Successfully deleted",
    "message_error_delete": "Failed to delete",
    "message_success_upload": "Successfully uploaded",
    "message_error_upload": "Failed to upload",
    "message_success_bind": "Successfully linked",
    "message_error_bind": "Failed to link",
    "message_no_token": "Log in timed out, please log in again",
    "message_no_permission": "You don't have permission",
    "message_sms_sended": "The verification code has been sent",
    "message_sms_error": "Failed to send verification code",
    "message_email_sended": "The code has been sent to your e-mail",
    "message_email_error": "Failed to send code to e-mail",
    "message_verify_success": "Verification successful",
    "message_verify_fail": "Verification failed",
    "not_found_title": "The page you are looking for is not found",
    "not_found_tips": "You may have entered through the wrong path",
    "empty_no_data": "There is no data",
    "empty_on_dev": "In preparation",
    "upgrading_tip": "Update in progress. Please wait until the opening day.",
    "upload_avatar": "Profile picture",
    "device_reminder_content": "The mobile version is under preparation.\nPlease connect via desktop or desktop mode.",
    "ready_subtitle": "升级中，请稍等",
    "platform_hao_kan": "Haokan Video",
    "platform_tencent": "Tencent Video",
    "platform_iqiyi": "iQIYI",
    "platform_bilibili": "bilibili",
    "platform_xi_gua": "Xigua Video",
    "platform_wei_bo": "Weibo",
    "platform_youtube": "YouTube",
    "platform_dou_yin": "Douyin",
    "platform_red_book": "Xiaohongshu",
    "platform_kuai_shou": "Kuaishou",
    "platform_cloud_music": "NetEase Cloud Music",
    "platform_wechat_video": "WeChat Video",
    "platform_mei_pai": "Meipai",
    "platform_da_yu": "Dayuhao",
    "platform_dian_tao": "Diantao",
    "platform_qq_world": "QQ World",
    "platform_tiktok": "TikTok",
    "platform_instagram": "Instagram",
    "platform_twitter": "Twitter",
    "country_USA": "US",
    "country_CAN": "Canada",
    "country_KRW": "Korea (South)",
    "country_CHN": "China"
  },
  "dashboard": {
    "home_page": "Home",
    "material_management": "File storage",
    "content_management": "Content ",
    "platform_management": "Platform",
    "subtitle_management": "Subtitles",
    "data_analysis": "Data analysis",
    "commercial_monetization": "Business management",
    "wallet": "My wallet",
    "repost_management": "Copyright management",
    "creative_laboratory": "Lab",
    "setting": "Settings",
    "feedback": "Feedback",
    "close_sidebar": "Fold",
    "upload_video": "Upload",
    "lang_language": "Language",
    "lang_zh_cn": "中文(简体)",
    "lang_ko_kr": "한국어",
    "lang_en_eu": "English",
    "auth": "Pass center",
    "auth_certified": "Verified",
    "auth_uncertified": "Unverified",
    "account_management": "My channels",
    "sign_out": "Log out"
  },
  "sign": {
    "signin_title": "Welcome!",
    "signin_subtitle": "From channel opening to revenue settlement, meet the A to Z of Chinese platform operations on adobaro",
    "email_holder": "Enter your e-mail address",
    "email_error": "Incorrect e-mail format",
    "signin_email_holder": "Enter your mobile phone number or e-mail address",
    "signin_email_error": "Incorrect e-mail/mobile number format",
    "forgot_password": "Forgot your password?",
    "signup": "Sign up",
    "signup_tip": "{0} if you don't have an account",
    "account_not_exist": "We couldn't find the account information you entered.\nPlease check and try again.",
    "signin_error": "Failed to log in",
    "google_signin_error": "Failed to log in with Google",
    "signin_warning": "An overseas IP/security issue has been detected. Please proceed with security verification from the email you signed up for.",
    "signin_fail": "Incorrect password",
    "forgot_title": "Forgot your password?",
    "forgot_subtitle": "A verification e-mail will be sent to the registered e-mail address.",
    "forgot_btn": "Send verification e-mail",
    "forgot_email_send": "The verification e-mail has been sent.",
    "forgot_email_send_error": "Failed to send e-mail",
    "verify_success": "Successfully verified",
    "verify_fail": "Failed to verify",
    "verify_success_tip": "Verification is complete. Thank you.",
    "verify_fail_tip": "Invalid link or verification timed out",
    "btn_signin_retry": "Log in again",
    "account_exist": "The account is already registered",
    "signup_age_14": "I am over 14 years old.",
    "signup_user_protocol": "adobaro User Agreement",
    "signup_privacy_protocol": "The Privacy Policy of adobaro",
    "signup_marketing_protocol": "(optional) Marketing Consent Form",
    "signin": "Log in",
    "signin_tip": "{0} if you already have an account.",
    "signup_email_send": "A verification e-mail will be sent to the registered e-mail address.\nSet up your password after the verification is complete.",
    "signup_email_send_error": "Failed to sign up",
    "password_set": "Set up new password",
    "password_reset": "Reset password",
    "password_set_subtitle": "You have been verified through e-mail. Please set up a secure password.",
    "password_reset_subtitle": "You have been verified through e-mail. Please set up a new secure password.",
    "btn_password_set": "Password settings",
    "btn_password_reset": "Reset password",
    "password_set_success": "Successfully set up password",
    "password_reset_success": "Successfully reset password",
    "password_set_fail": "Failed to set up password",
    "password_reset_fail": "Failed to reset password",
    "password_set_code_invalid": "The verification code has expired.\nPlease apply again.",
    "password_reset_code_invalid": "The verification code has expired.\nPlease apply again."
  },
  "auth": {
    "modal_title": "Global Pass required",
    "modal_subtitle": "Global pass verification is required when opening a platform, but more importantly, you must verify Global pass in order to generate revenue from activities on each platform.",
    "modal_plus_title": "Global Pass Plus required",
    "modal_plus_subtitle": "You can access the following after completing the Global Pass Plus.",
    "modal_item1": "The start of revenue generation from platform activities!",
    "modal_item2": "More platform openings",
    "modal_item3": "Customized data analysis sytem",
    "modal_item4": "and many more features.",
    "modal_btn_next": "Next time",
    "modal_btn_go": "Start verification",
    "auth_center": "Pass center",
    "auth_center_subtitle": "Pass management",
    "auth_global": "Global Pass",
    "auth_creator": "Global Pass Plus",
    "auth_history": "Pass usage record",
    "auth_global_subtitle": "Platform monetization, channel safety improvement",
    "auth_creator_subtitle": "Can be opened after fulfilling conditions",
    "auth_history_subtitle": "Pass usage history of each platform",
    "auth_global_title": "Global Pass",
    "auth_creator_title": "Global Plus Pass",
    "auth_history_title": "Pass usage record",
    "creator_auth_tip": "Please proceed with the Global Pass first.",
    "pass_title": "Successfully verified",
    "passport_title": "Passport information",
    "platform_title": "Platform information",
    "platform_name": "Platform name",
    "platform_url": "Platform URL",
    "passport_dashboard_tip": "Scanned image of signed passport",
    "passport_handhold_tip": "A selfie with your signed passport",
    "platform_dashboard_tip": "Screenshot of studio dashboard",
    "platform_handhold_tip": "A selfie with your studio dashboard",
    "btn_submit_picture": "I agree to provide information for verification.",
    "protocol_tip": "I have fully understood {0}, and agree to apply.",
    "protocol_privacy": "保护个人信息协议",
    "protocol_platform": "adobaro General Power of Attorney",
    "pass_no": "Verification code",
    "pass_duration": "Verification period",
    "pass_explain": "After {0}'s passport information is uploaded, it is not disclosed to protect personal information.",
    "pass_in_review": "{0} has been applied.\nPlease wait for the review.\nThe review will take about 3~5 business days.",
    "pass_in_fail": "{0} has failed the review.\nReason: ",
    "pass_expire_warning": "{0} and {1} are set to expire.\nPlease apply again to use the service.",
    "pass_expire_already": "{0} has been expired.\nPlease apply again to use the service.",
    "pass_submit_success_tip": "{0} has been successfully submitted.\nPlease wait for the review.\nThe review will take about 3~5 business days.",
    "pass_submit_fail_tip": "Failed to submit {0}",
    "auth_attention": "Cautions for verification",
    "global_attention_question_1": "1. Why is my passport required?",
    "global_attention_answer_1": "Answer",
    "global_attention_question_2": "Question 2",
    "global_attention_answer_2": "Answer",
    "global_attention_question_3": "Question 3",
    "global_attention_answer_3": "Answer",
    "global_attention_question_4": "Question 4",
    "global_attention_answer_4": "Answer",
    "global_attention_pass_question_1": "1. How do I cancel my Global Pass after verification?",
    "global_attention_pass_answer_1": "Answer",
    "global_attention_pass_question_2": "Question 2",
    "global_attention_pass_answer_2": "Answer",
    "creator_attention_question_1": "1. Why is the platform dashboard required?",
    "creator_attention_answer_1": "Answer",
    "creator_attention_question_2": "Question 2",
    "creator_attention_answer_2": "Answer",
    "creator_attention_question_3": "Question 3",
    "creator_attention_answer_3": "Answer",
    "creator_attention_question_4": "Question 4",
    "creator_attention_answer_4": "Answer",
    "creator_attention_pass_question_1": "1. After verification, how do I cancel my Global Pass Plus verification?",
    "creator_attention_pass_answer_1": "Answer",
    "creator_attention_pass_question_2": "Question 2",
    "creator_attention_pass_answer_2": "Answer",
    "attention_know_more": "Learn more",
    "cancel_auth": "Cancel Pass verification",
    "cancel_auth_title": "Are you sure you want to cancel Pass verification?",
    "cancel_auth_subtitle": "If the Pass verification is canceled, revenue from all operating channels will be suspended, and all opened channels will be withrawn. Would you like to proceed?",
    "cancel_auth_success": "Successfully canceled Pass verification",
    "cancel_auth_fail": "Failed to cancel Pass verification",
    "attention_approve": "Satisfied with the answer?",
    "history_country": "Country",
    "history_platform": "Platform",
    "history_auth_type": "Pass type",
    "history_use_todo": "Purpose",
    "history_timestamp": "Time",
    "use_type_handling": "Copyright takedown",
    "use_type_open_income": "Platform revenue",
    "use_type_open_platform": "Platform opening"
  },
  "home": {
    "play_more_title": "a.coin rewards",
    "play_more_new": "Must-read guide for beginners",
    "play_more_email": "Link e-mail",
    "play_more_platform": "Platform opening",
    "play_more_video": "Content distribution",
    "tour_upload": "Meet more subscribers by uploading your content.",
    "tour_material": "You can manage the original source of the uploaded content.",
    "tour_content": "You can manage content distributed on the platforms.",
    "tour_platform": "You can manage opened platforms and platforms that can be opened.",
    "tour_coin": "You can manage a.coin usage, purchase, and acquisition history.",
    "tour_account": "You can manage your account and profile.",
    "unlock_describe": "You can get {0} a.coins upon completion.",
    "play_more_done": "acquired",
    "play_more_unlock": "acquire",
    "read_more": "Read more",
    "all_platform_statistic": "Overall platform data statistics",
    "statistic_date": "Last updated on {0}",
    "statistic_platform": "Opened platforms",
    "all_platform_fans": "Total platform subscribers",
    "all_platform_plays": "Total platform views",
    "latest_content": "Latest content",
    "latest_popular": "My popular platform",
    "all_coin": "a.coin",
    "coin_rule": "What is a.coin?",
    "read_details": "Details",
    "coin": "a.coin",
    "platform_news": "Platform news",
    "news_content": "Welcome  to adobaro!\nIn platform news, we introduce stories and new features of adobaro. Please look forward to updates.",
    "news_help_btn": "Explore help center",
    "unlocked_get_coin": "You can get {0} a.coins upon completion.",
    "unlocked_task_card": "Check my task card",
    "unlocked_to_content": "Content management",
    "unlocked_to_upload": "Upload content",
    "unlocked_to_open": "Platform opening",
    "unlocked_upload_tips": "Open platforms and make full use of adobaro."
  },
  "upload": {
    "file_btn_status_removed": "Upload",
    "file_btn_status_uploading": "Uploading",
    "file_btn_status_done": "Change file",
    "file_btn_status_error": "Reupload",
    "file_dragger_subtitle": "Upload or drag and drop file here",
    "file_dragger_tip": "{0} content can be uploaded",
    "file_status_uploading": "Successfully uploaded",
    "file_status_done": "Complete",
    "file_status_error": "Failed to upload",
    "file_type_error": "Only {0} file formats are supported",
    "file_limit_size": "File size must not exceed {0} Mb",
    "file_limit_size_tip": "(within {0} Mb)",
    "file_change_tip": "Change file",
    "file_upload_image_tip": "Upload thumbnail",
    "file_change_image_tip": "Change thumbnail",
    "file_name_already_exist": "The file name already exists.\nPlease change the file name and upload again",
    "file_size_exceeds_capacity": "Storage is full.\nPlease check the remaining capacity.",
    "example_pic_tip": "Sample",
    "guide_tips": "All you need is {0}",
    "guide_tip_content": "original video, thumbnail, title, and description",
    "guide_subtitle": "adoba AI rewrites the title and description in the language of the country, generates thumbnails for each platform, and uploads the content optimized for each platform.",
    "guide_upload_btn": "Upload",
    "page_title": "Upload settings",
    "page_subtitle": "Your content will be uploaded to the opened platforms.",
    "upload_file_holder": "Please upload file",
    "upload_file_complete_tip": "Please proceed after file upload is complete.",
    "select_platform": "Select platforms",
    "select_platform_holder": "Please select platforms to upload",
    "open_more_platform": "More platforms",
    "basic_info": "Content settings",
    "basic_info_tip": "adoba AI automatically converts and uploads content optimized for each platform.",
    "basic_content_title": "Title",
    "basic_content_title_holder": "Please enter title",
    "basic_content_cover": "Thumbnail",
    "basic_content_cover_holder": "Please upload thumbnail",
    "basic_content_cover_upload": "{0} thumbnail can be uploaded",
    "basic_content_cover_upload_tip": "Thumbnail images must not contain any texts.",
    "basic_content_cover_change_tip": "Change thumbnail",
    "basic_content_intro": "Description",
    "basic_content_intro_holder": "Please enter description",
    "basic_content_subtitle": "Select subtitles",
    "basic_content_subtitle_holder": "Please select subtitles",
    "basic_content_timer_release": "Schedule upload",
    "basic_content_timer_release_tip": "You can only select time after {0} hours",
    "btn_release": "Upload",
    "btn_draft": "Save draft",
    "draft_title": "Saved drafts",
    "draft_subtitle": "There is a draft that hasn't been uploaded yet.\nWould you like to continue?",
    "draft_read_image_error": "Failed to load image. Please try again.",
    "draft_save_tips": "Draft can be saved after upload is completed.",
    "draft_save_success": "Draft saved",
    "release_content_success": "The review of your uploaded content will begin shortly.\nYou can check the result in 'Content',",
    "release_content_fail": "Failed to upload",
    "release_free_count": "{0} free uploads left this week",
    "release_limit_tip_1": "1. Uploading content requires {0} a.coins.",
    "release_limit_tip_2": "2. Free upload is available once a week.",
    "release_limit_tip_3": "3. Global Pass users can upload 3 times a week for free.",
    "release_limit_tip_4": "4. Global Pass Plus users can upload 5 times a week for free."
  },
  "material": {
    "page_title": "File Storage",
    "page_subtitle": "Easily manage uploaded content and distribute to other platforms all at once",
    "sider_my_file": "My documents",
    "sider_enlarge_capacity": "Storage",
    "menu_video": "Video",
    "menu_images": "Image",
    "menu_sounds": "Sound effect",
    "menu_subtitles": "Subtitles",
    "menu_fonts": "Font",
    "menu_bgm": "BGM",
    "menu_backup_space": "Backup cloud",
    "alert_capacity": "Expand your storage space to make full use of adobaro features",
    "alert_upgrade": "Expand storage space",
    "all_backups": "Backup all",
    "all_delete": "Delete all",
    "all_download": "Download all",
    "confirm_title_delete": "Are you sure you want to delete?",
    "confirm_ok": "Delete",
    "confirm_cancel": "Cancel",
    "selected_items": "{0} files selected",
    "backup_btn": "Backup",
    "delete_btn": "Delete",
    "download_btn": "Download",
    "pagination_total": "{0} files",
    "video_title": "All videos",
    "video_delete_confirm": "Are you sure you want to delete {0} videos?",
    "video_deleted_description": "Secondary distribution of deleted videos on new platforms is not possible",
    "video_name": "File name",
    "video_size": "Size",
    "video_published_platform": "Distribution platform",
    "video_opened_platform": "Opened platform",
    "image_title": "All images",
    "subtitles_title": "All subtitles",
    "subtitles_delete_confirm": "Are you sure you want to delete {0} documents?",
    "subtitles_deleted_description": "Secondary distribution of deleted videos on new platforms is not possible",
    "subtitles_name": "File name",
    "subtitles_size": "Size",
    "subtitles_lang": "Language",
    "modal_title": "Change name",
    "modal_name_label": "Name",
    "modal_name_holder": "Enter name"
  },
  "content": {
    "page_title": "Content Management",
    "page_subtitle": "Manage content uploaded on each platform.",
    "radio_all": "All",
    "radio_play": "Sort by views",
    "radio_like": "Sort by likes",
    "page_details_title": "View content details",
    "base_info": "Basic info",
    "base_video": "Video",
    "base_cover": "Thumbnail",
    "base_title": "Title",
    "base_subtitle": "Subtitles",
    "base_upload_date": "Uploaded on",
    "empty_cover": "Thumbnail is missing",
    "distributed_platform": "Uploaded platforms",
    "distributed_name": "Platform",
    "distributed_state": "Status",
    "distributed_subtitle": "Subtitles",
    "distributed_plays": "Views",
    "distributed_likes": "Likes",
    "distributed_upload_date": "Uploaded on",
    "distributed_action": "Execute",
    "status_playing": "Upload complete",
    "status_opening": "Upload in progress",
    "status_failure": "Failed to upload",
    "status_unpublished": "Undistributed",
    "status_not_opened": "Not opened",
    "action_operate": "Execute",
    "action_ask": "Ask for help",
    "action_publish": "Upload at once",
    "action_open": "Open",
    "action_operate_check": "View",
    "action_operate_analysis": "Analysis",
    "action_operate_edit": "Edit",
    "action_operate_delete": "Delete",
    "republish_platform_success": "Successfully uploaded",
    "republish_platform_error": "Failed to upload",
    "video_url_error": "Upload URL not found"
  },
  "platform": {
    "guide_title": "Are you operating a platform?",
    "guide_subtitle": "If you are operating a platform, please enter the URL below.\nWe will recommend the Chinese platform that suits you best.",
    "guide_platform_holder": "Select platform",
    "guide_platform_url_holder": "Enter your channel ID",
    "guide_platform_url_error": "Confirm your channel ID",
    "guide_platform_already": "The platform channel has already been registered.",
    "guide_platform_confirm": "Once you submit your channel information, it cannot be edited.\nPlease press 'Confirm' if the information is correct.",
    "guide_platform_tip": "Where can I view my @channel ID?",
    "guide_btn_recommend": "Platform recommendation",
    "guide_platform_error": "Failed to recommend platform",
    "guide_skip_title": "Later",
    "guide_skip_subtitle": "Please tell us why you would like to do it later",
    "guide_skip_holder": "Select reason",
    "guide_skip_reason_1": "I am not operating a channel",
    "guide_skip_reason_2": "I already have a channel opened",
    "guide_skip_reason_3": "The platform I want is unavailable",
    "guide_skip_reason_4": "Other",
    "guide_skip_btn": "Go to home",
    "guide_analysis_title": "Analyzing recommendation",
    "guide_analysis_subtitle": "The screen will automatically advance after completion.",
    "guide_analysis_fail_title": "There are too many current users.",
    "guide_analysis_fail_subtitle": "We will notify you via e-mail once the analysis is complete.",
    "guide_analysis_fail_btn_next": "Later",
    "guide_analysis_fail_btn_confirm": "Tell me more",
    "guide_analysis_done_title": "Platform analysis complete",
    "guide_analysis_done_subtitle": "We have recommended the best Chinese platform upon analyzing your current platform",
    "guide_analysis_platform_about": "Learn more",
    "guide_analysis_btn_open": "Open platform",
    "guide_analysis_btn_already": "Already have an account?",
    "guide_analysis_btn_next": "Maybe next time",
    "guide_recommend_fail": "Failed to open platform",
    "guide_bind_title": "Link channel",
    "guide_bind_subtitle": "If you have a channel that has already been opened, try linking it to adobaro.\nYou can manage all platforms at once.",
    "guide_bind_tip": "Why do I need to enter my password?",
    "guide_bind_btn_confirm": "Link channel",
    "guide_bind_btn_next": "Link next time",
    "guide_bind_success": "Platform opening application submitted.\nWe will soon notify you via e-mail.",
    "guide_open_phone_title": "Sign up to Platform",
    "guide_open_phone_subtitle": "You need a cell phone number that can receive text messages to sign up for the Chinese platform, .",
    "guide_open_btn_verify": "Send verification code",
    "guide_open_submit_success": "An e-mail has been sent for verification.",
    "guide_open_send_sms_error": "Failed to send verification code",
    "guide_open_verify_title": "Send verification code",
    "guide_open_verify_subtitle": "A verification text for the platform will be sent shortly.\nPlease enter your verification code corresponding to ****** below.",
    "guide_open_btn_verifying": "Verify",
    "guide_open_verify_success": "Checking verification.\nPlease wait.",
    "guide_open_verify_error": "Failed to verify",
    "manage_title": "Platform management",
    "manage_subtitle": "Here, you can manage already opened platforms and platforms to be opened",
    "applied_platform": "My platforms",
    "btn_refresh": "Refresh",
    "btn_refresh_tip": "Refresh",
    "btn_all": "All",
    "btn_play_most": "Most viewed",
    "btn_like_most": "Most liked",
    "platform_empty": "Explore more platforms",
    "status_done": "Successfully opened",
    "status_opening": "Opening in progress. Please wait.",
    "status_verifying": "Verification in progress. Please wait.",
    "status_verify_failed": "Verification failed",
    "status_failed": "Opening failed",
    "btn_status_done": "Upload",
    "btn_status_verify": "Enter verification code",
    "btn_status_failed": "View details",
    "un_apply_platform": "More platforms",
    "open_count": "{0} users have already opened",
    "lastest_open": "{0} {1} users opened",
    "btn_open": "Open",
    "btn_reapply": "Apply again",
    "open_platform_title": "Platform opening",
    "open_platform_subtitle": "We simply and safely open the Chinese platforms which were complicated and inconvenient.",
    "details_title": "Platform summary",
    "details_info_title": "Basic information",
    "details_info_avatar": "Profile picture",
    "details_info_intro": "Channel description",
    "details_info_intro_empty": "There is no channel description",
    "details_info_intro_holder": "Please enter channel description",
    "details_info_date": "Opened on",
    "details_info_date_empty": "There is no data",
    "details_info_date_holder": "Please select the channel subscription date,",
    "details_info_classify": "Channel category",
    "details_info_classify_empty": "There is no data",
    "details_info_classify_holder": "Please select your category",
    "details_info_income": "Platform revenue",
    "details_info_order": "Advertisements",
    "details_info_btn_again": "New channel name",
    "details_info_btn_open": "Open now",
    "details_info_btn_edit": "Edit basic information",
    "details_info_btn_submit": "Save and edit",
    "details_data_overview_title": "Data summary",
    "details_data_radio_yesterday": "Yesterday",
    "details_data_radio_nearly7": "Last 7 days",
    "details_data_radio_nearly30": "Last 30 days",
    "details_data_fans": "Total subscribers",
    "details_data_likes": "Total likes",
    "details_data_platform": "Total platform revenue",
    "details_data_published": "Total distributions",
    "details_data_plays": "Total views",
    "details_data_ad": "Total advertising revenue",
    "details_release_video_title": "Distributed content"
  },
  "wallet": {
    "title": "My wallet",
    "subtitle": "a.coin is a platform virtual currency in adobaro, and can be withdrawn when a certain amount is accumulated.",
    "overview_title": "Current a.coins",
    "overview_statistic_title": "a.coin",
    "overview_btn_deposit": "Purchase",
    "overview_btn_withdrawal": "Withdraw",
    "record_title": "a.coin records",
    "record_enter": "Deposit records",
    "record_out": "Withdrawal records",
    "enter_date": "Time of deposit",
    "enter_content": "History",
    "enter_all": "All",
    "out_date": "Time of withdrawal",
    "out_content": "History",
    "out_all": "All",
    "coin_unit": "a.coin",
    "scene_init_account": "Subscription",
    "scene_open_platform": "Platform opening",
    "scene_bind_platform": "Platform link",
    "scene_new_video": "Content upload",
    "scene_push_video": "Platform upload",
    "scene_withdrawal": "Settled",
    "scene_recharge": "Purchase",
    "scene_platform_settlement": "Platform revenue",
    "scene_gift": "pre-registering",
    "get_tips": "The a.coin has been deposited to my wallet.",
    "get_rule_title": "About a.coin",
    "get_rule_content": "a.coin is a currency used in adobaro that can be used for your channel growth, and can be monetized through applying for settlement.",
    "cost_use_btn": "Use",
    "cost_insufficient_title": "Sorry",
    "cont_insufficient_subtitle": "Insufficient a.coins",
    "cost_insufficient_tips": "You need {0} a.coins, but you currently have {1} a.coins."
  },
  "setting": {
    "title": "Settings",
    "subtitle": "You can manage settings related to adobaro.",
    "user_info": "User info",
    "account_security": "Account security",
    "notification": "Notification",
    "protocol": "Terms of use",
    "user_uid": "UID",
    "user_phone": "Mobile number",
    "user_email": "E-mail address",
    "user_password": "Password",
    "user_third_party_login": "Third party log in",
    "user_not_set": "Not set",
    "user_set_password": "Change password",
    "user_to_change": "Change",
    "user_bound": "Linked",
    "user_to_bound": "Link",
    "user_bound_google": "Linked with Google",
    "user_unbound_google": "Link with Google",
    "user_problem_center": "Customer service",
    "user_phone_title_bind": "Enter mobile phone number",
    "user_phone_subtitle_bind": "For account safety, it will be proceeded after confirming your mobile verification code.",
    "user_phone_title_new": "Enter new mobile phone number",
    "user_login_country_check": "Select your country",
    "user_modify_phone_verify_fail": "Failed to verify mobile phone number",
    "user_support_email": "Customer service e-mail",
    "user_code_not_received_tip": "Please contact customer service if you can't receive verification codes.",
    "user_code_not_received": "Haven't received your verification code?",
    "user_verify_header": "ID verification",
    "user_modify_phone_header": "Change mobile phone number",
    "user_modify_email_update_button": "Change e-mail",
    "user_modify_email_explanation": "For account safety, it will be proceeded after confirming your e-mail verification code",
    "user_modify_email_old_holder": "Enter your current e-mail address",
    "user_modify_email_old_popover": "If you do not remember your current email address, please contact customer service.",
    "user_modify_email_forgot": "Forgot your e-mail?",
    "user_modify_email_verify_fail": "Failed to verify e-mail",
    "user_modify_new_email": "New e-mail",
    "user_modify_email_new_holder": "Enter new e-mail",
    "user_modify_email_title": "Change e-mail",
    "user_modify_password_change_button": "Change password",
    "user_modify_password_title": "Change password",
    "user_modify_password_explanation": "For account safety, it will be proceeded after confirming your e-mail verification code.",
    "user_modify_new_password": "New password",
    "user_modify_password_new_holder": "Enter new password",
    "user_modify_password_confirm_holder": "Confirm new password",
    "user_modify_password_head": "Change password",
    "security_signin": "Log in security",
    "security_credit": "Credibility",
    "security_signin_date": "Logged in on",
    "security_signin_mode": "Logged in with",
    "security_signin_third": "Third party log in",
    "security_signin_google": "Log in with Google",
    "security_signin_account": "Logged in account",
    "security_signin_ip": "IP address",
    "security_signin_country": "Country",
    "security_signin_action": "Execute",
    "delete_confirm_title": "Are you sure?",
    "credit_action_date": "Executed on",
    "credit_action_behavior": "Action",
    "credit_ score": "Credibility",
    "notice_spread": "Marketing notification",
    "notice_platform": "Platform notification",
    "notice_sms": "SMS notification",
    "notice_email": "e-mail notification",
    "notice_adobaro": "adobaro",
    "notice_operate": "Operating platforms",
    "notice_select_holder": "Please select",
    "notice_agree": "Agree",
    "notice_disagree": "Disagree",
    "user_problem_question_1": "Question 1",
    "user_problem_answer_1": "Answer",
    "user_problem_question_2": "Question 2",
    "user_problem_answer_2": "Answer",
    "user_problem_question_3": "Question 3",
    "user_problem_answer_3": "Answer",
    "user_problem_question_4": "Question 4",
    "user_problem_answer_4": "Answer",
    "protocol_user": "adobaro User Agreement",
    "protocol_privacy": "The Privacy Policy of adobaro",
    "protocol_marketing": "Marketing Consent Form"
  },
  "email": {
    "1": "Title and description review",
    "2": "Sensitive or inappropriate content has been detected.",
    "3": "Does not match with content.",
    "4": "Content that violates Chinese laws has been detected",
    "6": "Thumbnail review",
    "7": "The thumbnail contains violations. Revealing genitals or physically sensitive areas, tt could be suspected as an offensive image, or it contains vulgar content, profanity, and content that is against public order and morals.",
    "9": "The thumbnail is overstretched, the screen is lost, or the brightness is too low or overexposed.",
    "10": "The thumbnail resolution is too low.",
    "11": "The thumbnail and the video content does not match.",
    "13": "Rejected due to the background has been removed, the portait does not match, or the image is low-quality.",
    "14": "The thumbnail contains external content or information.",
    "15": "Content review",
    "16": "There is a problem with the content quality.",
    "17": "The video is low resolution, the screen loses, and the brightness/contrast/resolution/blurry screen makes it difficult to distinguish the subject.",
    "18": "Invalid video URL, 404 error, and the video is shorter than 6 seconds.",
    "19": "The video content is missing a clear storyline.",
    "20": "The video does not have any clear content.",
    "21": "The video does not play smoothly and lags.",
    "22": "The screen shakes noticeably or continuously for more than 5 seconds and flashes continuously for more than 3 seconds, which affects the viewing experience.",
    "23": "The subject of the screen is not complete, and is constantly clipped(≥3 seconds), severely stretched or compressed, has black outlines on either side or is filled with black outlines.",
    "24": "The content has been rejected because there is no audio.",
    "25": "The black screen of the opening and ending should not exceed 3 seconds.",
    "26": "The quality of the image is low.",
    "27": "The video content may cause legal issues.",
    "28": "The video content violates the Chinese law as it is believed to insult images or words of China's national leaders, attackers, prosecutors, state institutions, national flag, etc.",
    "29": "The video content contains sexual, blood, or horror elements that are illegal, violating or vulgar.",
    "30": "The video content contains elements related to cults, strife, and reactionary organizations.",
    "31": "The video content may harm the physical and mental health of minors or people with disabilities.",
    "32": "Minors cannot shoot commercial marketing content as representatives.",
    "33": "The video content violates elements related to public order and social values. This includes infidelity, domestic violence, display, discrimination, propaganda, smoking, foul language, pranks, bad behavior, abuse, drinking, tattoss, etc.",
    "34": "The video content contains sexually suggestive behavior, vulgar scenes, vulgar dnacing, inappropriate sexual relationship, etc.",
    "35": "The video content contains severe exposure.",
    "36": "There is a problem with the sound quality of the video.",
    "37": "The video suddenly mutes or the audio disappears, the audio tracks overlap inconsistently or are not related, and the audio is severely out of sync with the video.",
    "38": "There is too much noise.",
    "39": "There is a problem with the subtitles quality.",
    "40": "Subtitles are unclear, lost, overlapping, or sentences are delivered out of order.",
    "41": "The foreign langue video is missing Chinese subtitles or is not recommended due to subtitles quality issues.",
    "42": "Subtitles are not synchronized with or irrelevant to the video or audio.",
    "43": "Subtitles are cut off.",
    "44": "Subtitles are covering most of the person's face.",
    "signup_title": "Welcome!",
    "signup_subtitle": "This is the adobaro sign up procedure.",
    "signup_set_password_tip": "For account security, please set up your password after verifying your e-mail.",
    "signup_set_password": "Set up password",
    "sigin_warning_title": "Security issue",
    "sigin_warning_subtitle": "An overseas IP connection has been detected.",
    "sigin_warning_midtitle": "For account security, please proceed with the log in verification below.",
    "sigin_warning_verify": "Log in verification",
    "sigin_warning_not_myself": "If you did not log in,",
    "sigin_warning_change_pwd": "please change your password immediately.",
    "sigin_warning_pwd": "Change password",
    "reset_pwd_title": "Reset password",
    "reset_pwd_subtitle": "You have been requested to reset your password.",
    "reset_pwd_midtitle": "For account security, you can reset your password after verifying your e-mail.",
    "reset_pwd_set": "Password settings",
    "release_title": "Upload status",
    "release_audit_fail": "Review failed",
    "release_upload_video": "The content you uploaded",
    "release_audit_reason": "has failed the review.\nThe reason is as follows.",
    "release_re_upload": "Re-upload",
    "release_success": "Upload complete",
    "release_up_video": "The content you uploaded",
    "release_up_platform": "has been successfully uploaded on platform {{.Platform}}.",
    "release_view_data": "Content data",
    "op_notice_title": "Platform opening status",
    "op_notice_fail": "Failed to open platform",
    "op_notice_fail_platform": "The {{.Platform}} platform you applied for could not be opened.",
    "op_notice_fail_reason": "Reason for failure",
    "op_notice_success": "Successfully opened platform",
    "op_notice_success_platform": "The {{.Platform}} platform you applied for has been opened.",
    "op_notice_ai": "Suitable channel names and channel descriptions have been optimized and applied to the platform through adoba AI.",
    "op_notice_view_data": "View platform information",
    "certification_title": "Pass verificaiton notice",
    "certification_subtitle_global_fail": "Global Pass review failed",
    "certification_global_fail": "Your Global Pass verification has failed the review.",
    "certification_subtitle_creator_fail": "Creator Pass review failed",
    "certification_creator_fail": "Your Creator Pass verification has failed the review.",
    "certification_view_reason": "Reason for failure",
    "certification_in_applying": "You can use the following features after passing the verification review.",
    "certification_global_success": "Global Pass verification complete",
    "certification_subtitle_global_success": "Your Global Pass verification has been completed.",
    "certification_open_now": "You can use the following features for various creative activities from now on.",
    "certification_subtitle_creator_success": "Creator Pass verification complete",
    "certification_creator_success": "Your Creator Pass verification has been completed.",
    "certification_earnings": "More content revenue generation",
    "certification_auth_cancel": "Pass verification cancellation notice",
    "certification_cancel_start": "Your {{开始时间}} Pass verification has been successfully canceled.",
    "certification_get_a_coin": "Platform revenue will be suspended from the date of application, but a.coins can still be used within adobaro",
    "certification_a_coin": "Learn about a.coin",
    "certification_re_register": "If you would like to apply again for platform opening,",
    "certification_cancel_end": "Platform can be reopened after {{结束时间}}.",
    "certification_thank": "Thank you for using our service.",
    "captcha_notice_title": "Verification code notice",
    "captcha_notice_subtitle": "Check verification code",
    "captcha_notice_hello": "Hello,",
    "captcha_notice_platform": "Platform",
    "captcha_notice_send_code": "verification code will be sent soon.",
    "captcha_notice_platform_secure": "Mobile phone verification code is required due to platform security.",
    "captcha_notice_sgin": "Please check your message inbox. <br>\nIf you have already entered your verification code within adobaro, please ignore this e-mail.",
    "captcha_notice_fill_code": "Send verification code",
    "captcha_notice_not_myself": "If you didn't apply,",
    "captcha_notice_handle": "please contact adobaro customer service.",
    "captcha_notice_foot": "This e-mail does not support replies.",
    "verify_code_title": "Verification number notice",
    "verify_code_subtitle": "Please check your verification code.",
    "verify_code_hello": "Hello.",
    "verify_code_platform": "Platform",
    "verify_code_send_code": "The verification code will be sent once you select the button below.",
    "verify_code_resend": "Please check your verification code again.",
    "verify_code_security": "Mobile phone verification code is required due to platform security.",
    "verify_code_sign": "Please note.",
    "verify_code_fill_code": "Eneter verification code",
    "email_subject_sign_account": "[adobaro] Welcome to adobaro!",
    "email_subject_reset_pwd": "[adobaro] Please reset your password",
    "email_subject_sign_warning": "[adobaro] Log in security issue",
    "email_subject_auth": "[adobaro] Have you applied for the Pass verification?",
    "email_subject_release": "[adobaro] Progress of uploaded content",
    "email_subject_verify_code": "[adobaro] Verification code for account security",
    "email_subject_op_result": "[adobaro] Platform opening application result",
    "email_snject_verify_email": "[adobaro] Verification e-mail",
    "email_verify_code_subtitle": "This is a process for verification.",
    "email_verify_code_hello": "Hello, a verification code will be sent to verify your ID.",
    "email_verify_code_input": "Please enter below and proceed with verification.",
    "email_verify_code_not_myself": "If it is not you, please log in and change your email.",
    "email_verify_code_handle": "The verification code is not requested by the customer center either, so please be careful not to share it with others or leak it.",
    "email_verify_code_recover": "This e-mail does not support replies.",
    "email_verify_code_warning": "If you have any problems, please contact customer service.",
    "auth_err_1": "Passport photo is difficult to verify.\nPlease check and upload again.",
    "auth_err_2": "It is difficult to cross-check you and your passport image.\nPlease check and upload again.",
    "auth_err_3": "Passport expiration date has expired.\nPlease renew your passport.",
    "auth_err_4": "Passport information does not match.\nPlease enter the information correctly.",
    "auth_err_5": "The passport information already exists.",
    "auth_err_6": "Screenshot image is not clear enough.\nPlease check and upload again.",
    "auth_err_7": "It is difficult to cross-check you and your dashboard screenshot image.\nPlease check and upload again.",
    "auth_err_8": "The information extracted from the image and the information you entered do not match.\nPlease check and upload again.\r\n",
    "auth_err_9": "The dashboard information already exists.",
    "5 (这个不会存在）": "You have exceeded the number of characters that can be entered.",
    "8 （不存在）": "The thumbnail contains outlines.",
    "12 (不存在)": "The thumbnail text is cut off."
  }
};export default en