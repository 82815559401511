import { makeAutoObservable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import walletServices from '@/services/walletServices';
import { TransactionListParamsType, TransactionListType } from './types';

export default class WalletStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'WalletStore',
      properties: [],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  balance = 0;
  // 查询币余额
  async getBalance() {
    const res = await walletServices.getBalance();
    this.setBalance(res.balance);
    return Promise.resolve(res);
  }

  setBalance(count: number) {
    this.balance = count;
  }

  frozen = 0;
  // 查询冻结的币 数量
  async getFrozen() {
    const res = await walletServices.getFrozen();
    this.setFrozen(res.frozenSub);
    return Promise.resolve(res);
  }

  setFrozen(count: number) {
    this.frozen = count;
  }

  async getTransactionList(page: number, size: number, positive: boolean) {
    const params: TransactionListParamsType = {
      page,
      size,
      positive,
    };
    const res = await walletServices.getTransactionList(params);
    this.saveTransactionList(res.list);
    return Promise.resolve(res);
  }

  rollInList: TransactionListType[] = [];
  rollOutList: TransactionListType[] = [];
  saveTransactionList(list: TransactionListType[]) {
    this.rollInList = list.filter((item) => item.credit >= 0);
    this.rollOutList = list.filter((item) => item.credit < 0);
  }

  // 查询视频每周免费次数
  async getVideoFreeCount() {
    const res = await walletServices.getVideoFreeCount();
    return Promise.resolve(res);
  }
}
