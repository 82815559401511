import { makeAutoObservable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import userServices from '@/services/userServices';
import {
  CheckExistType,
  SignInType,
  SignUpType,
  SignOffType,
  SendEmailType,
  ResetPasswordType,
  UserType,
  ResetEmailType,
  ResetPhoneType,
  BindBindGoogleOauthType,
  GetVerifyCodeType,
  UpdateNoticeEmailType,
  GlobalPassType,
  CreatorPassType,
  CancelPassType,
  AuthHistoryType,
  OverseaPlatformParams,
  OperatePlatformParams,
  PlatformCodeParams,
  OperatePlatformType,
  PlatformDataType,
} from './types';
import ChannelService from '@/utils/ChannelService';
import StringUtils from '@/utils/StringUtils';
import dayjs from 'dayjs';

export default class UserStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'UserStore',
      properties: ['isOpenPlatform'],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  async checkExist(params: CheckExistType) {
    const res = await userServices.checkExist(params);
    return Promise.resolve(res);
  }

  async signIn(params: SignInType) {
    const res = await userServices.signIn(params);
    return Promise.resolve(res);
  }

  async googleAuthState() {
    const res = await userServices.googleAuthState();
    return Promise.resolve(res.state);
  }

  async sendEmailLink(params: SendEmailType) {
    const res = await userServices.sendEmailLink(params);
    return Promise.resolve(res);
  }

  async signUp(params: SignUpType) {
    const res = await userServices.signUp(params);
    return Promise.resolve(res);
  }

  async resetPassword(params: ResetPasswordType) {
    const res = await userServices.resetPassword(params);
    return Promise.resolve(res);
  }

  async signOff(params: SignOffType) {
    const res = await userServices.signOff(params);
    return Promise.resolve(res);
  }

  // 获取user info
  async getUser() {
    const res = await userServices.getUser();
    this.saveUser(res);
    return Promise.resolve(res);
  }

  async updateUserLanguage(lang: string) {
    const res = await userServices.updateUserLanguage({ lang });
    return Promise.resolve(res);
  }

  // 获取验证码
  async getVerifyCode(params: GetVerifyCodeType) {
    const res = await userServices.getVerifyCode(params);
    return Promise.resolve(res);
  }

  // 验证手机号/邮箱
  async checkVerifyCode(params: GetVerifyCodeType) {
    const res = await userServices.checkVerifyCode(params);
    return Promise.resolve(res);
  }

  // 重置邮箱
  async resetEmail(params: ResetEmailType) {
    const res = await userServices.resetEmail(params);
    return Promise.resolve(res);
  }

  // 重置手机号
  async resetPhone(params: ResetPhoneType) {
    const res = await userServices.resetPhone(params);
    return Promise.resolve(res);
  }

  // 绑定谷歌账号
  async bindGoogle(params: BindBindGoogleOauthType) {
    const res = await userServices.bindGoogle(params);
    return Promise.resolve(res);
  }

  // 获取登录信息
  async getSignInInfo() {
    const res = await userServices.getSignInInfo();
    return Promise.resolve(res);
  }

  // 移除登录信息
  async removeSignInInfo(id: number) {
    const res = await userServices.removeSignInInfo(id);
    return Promise.resolve(res);
  }

  // 更新通知状态
  async updateNoticeEmail(isNotice: UpdateNoticeEmailType) {
    const res = await userServices.updateNoticeEmail(isNotice);
    return Promise.resolve(res);
  }

  avatar: string = '';
  categoryId: number = 0;
  countryCode: string = '';
  email: string = '';
  gender: number = 0;
  gid: string = '';
  intro: string = '';
  isNotice: boolean = false;
  nickname: string = '';
  password: string = '';
  phone: string = '';
  uid: number = 0;
  saveUser(user: UserType) {
    this.avatar = user.avatar || '';
    this.categoryId = user.categoryId ? Number(user.categoryId) : 0;
    this.countryCode = user.countryCode || '';
    this.email = user.email || '';
    this.gender = user.gender || 0;
    this.gid = user.gid || '';
    this.intro = user.intro || '';
    this.isNotice = user.isNotice || false;
    this.nickname = user.nickname || '';
    this.password = user.password || '';
    this.phone = user.phone || '';
    this.uid = user.uid || 0;
    // this.realName = user.realName || '';

    this.updateChannelTalkUserInfo(user);
  }

  // 更新ChannelTalk用户信息
  updateChannelTalkUserInfo(user: UserType) {
    ChannelService.updateUser(
      {
        profile: {
          avatarUrl: user?.avatar || '',
          name: user?.nickname || '',
          mobileNumber: StringUtils.handleChannelTalkPhone(user?.phone),
          email: user?.email || '',
          // realName: user?.realName || '',
          intro: user?.intro || '',
          isAdReceive: user?.isNotice || '',
        },
        profileOnce: {
          avatarUrl: user?.avatar || '',
          name: user?.nickname || '',
          mobileNumber: StringUtils.handleChannelTalkPhone(user?.phone),
          email: user?.email || '',
          // realName: user?.realName || '',
          intro: user?.intro || '',
          isAdReceive: user?.isNotice || '',
        },
      },
      (err: any, cUser: any) => {
        if (err) {
          console.log('update channel talk user error ', err);
        } else {
          // console.log('update channel talk user success ', cUser);
        }
      }
    );
  }

  // 获取Global Pass信息
  globalPass: GlobalPassType = {
    id: 0,
    copyUrl: '',
    copyMoreUrl: '',
    status: 'CREATE',
  };
  isPassGlobal: boolean = false;
  async getGlobalPass() {
    const res = await userServices.getGlobalPass();
    this.saveGlobalPass(res);
    return Promise.resolve(res);
  }

  saveGlobalPass(pass: GlobalPassType) {
    if (pass.id) {
      this.globalPass = pass;
      this.isPassGlobal = pass.status === 'SUCCEED';
    }
  }

  // 提交Global Pass
  async submitGlobalPass(params: GlobalPassType) {
    const res = await userServices.submitGlobalPass(params);
    return Promise.resolve(res);
  }

  // 获取Creator Pass信息
  creatorPass: CreatorPassType = {
    id: 0,
    homepageCopyUrl: '',
    homepageMoreCopyUrl: '',
    platformId: 99,
    status: 'CREATE',
  };
  isPassCreator: boolean = false;
  async getCreatorPass() {
    const res = await userServices.getCreatorPass();
    this.saveCreatorPass(res.foreignPlatform);
    return Promise.resolve(res);
  }

  saveCreatorPass(pass: CreatorPassType) {
    if (pass.id) {
      this.creatorPass = pass;
      this.isPassCreator = pass.status === 'SUCCEED';
    }
  }

  // 提交Creator Pass
  async submitCreatorPass(params: CreatorPassType) {
    const res = await userServices.submitCreatorPass(params);
    return Promise.resolve(res);
  }

  async cancelAuthPass(params: CancelPassType) {
    const res = await userServices.cancelAuthPass(params);
    return Promise.resolve(res);
  }

  // 获取认证历史
  async getAuthHistory() {
    const res = await userServices.getAuthHistory();
    this.saveHistoryList(res.authHistories, res.count);
    return Promise.resolve(res);
  }

  authHistory: AuthHistoryType[] = [];
  authHistoryCount: number = 0;
  saveHistoryList(list: AuthHistoryType[], count: number) {
    this.authHistory = list;
    this.authHistoryCount = count;
  }

  // 创建海外平台
  async createOverseaPlatform(params: OverseaPlatformParams) {
    const res = await userServices.createOverseaPlatform(params);
    return Promise.resolve(res);
  }

  // 查询我运营中的平台列表
  async getOperatePlatform() {
    const res = await userServices.getOperatePlatform();
    this.saveOperatePlatform(res.list);
    return Promise.resolve(res);
  }

  // 是否有已经开通完成的平台
  isOpenPlatform: boolean = false;
  // 开通的平台列表 (包含 申请中、申请失败、开通完成)
  operatePlatforms: OperatePlatformType[] = [];
  saveOperatePlatform(list: OperatePlatformType[]) {
    this.operatePlatforms = list;

    let opened = false;
    if (list.length > 0) {
      list.forEach((item) => {
        if (item.openStatus === 'OS_DONE') {
          opened = true;
        }
      });
    }
    this.isOpenPlatform = opened;
    // 请求平台数据
    this.getPlatformData();
  }

  platformData: PlatformDataType[] = [];
  platformTotal: PlatformDataType = {};
  platformTotalIncrease: PlatformDataType = {};
  // 获取平台数据
  async getPlatformData() {
    const res = await userServices.getPlatformData();
    this.savePlatformData(res.byPlatform, res.incr, res.sum);
    return Promise.resolve(res);
  }

  // 保存平台数据
  savePlatformData(list: PlatformDataType[], increase: PlatformDataType, total: PlatformDataType) {
    this.platformData = list;
    this.platformTotalIncrease = increase;
    this.platformTotal = total;
    this.handlePlatformInfo();
  }

  async getPlatformIncreaseData() {
    const params = {
      metric: 'FANS',
      start: dayjs().subtract(3, 'days').format(),
      end: dayjs().format(),
    };
    const res = await userServices.getPlatformIncreaseData(params);
    // this.savePlatformIncreaseData(res.byPlatform, res.incr, res.sum);
    return Promise.resolve(res);
  }

  // 保存平台数据
  savePlatformIncreaseData() {}

  // 合并平台信息和平台数据
  handlePlatformInfo() {
    const mergedArray = this.operatePlatforms.map((platA) => {
      const matchingItem = this.platformData.find((platB) => platB.platformId === platA.platformId);

      if (matchingItem) {
        return {
          ...platA,
          sum: matchingItem,
        };
      }
      return platA;
    });

    this.sortOperatePlatforms(mergedArray);
  }

  sortOperatePlatforms(list: OperatePlatformType[], type = 'default') {
    const statusOrder = {
      OS_DONE: 0,
      OS_DOING: 1,
      OS_FAILED: 2,
      OS_VERIFY_FAILED: 3,
      OS_VERIFYING: 4,
      OS_PENDING: 5,
      OS_UNKNOWN: 6,
    };

    // 排序
    const sorted = list.sort((a, b) => {
      if (a.openStatus !== b.openStatus) {
        return statusOrder[a.openStatus] - statusOrder[b.openStatus];
      }
      // 当 status 为 'DONE' 时，按照 play / like 从大到小排序
      if (a.openStatus === 'OS_DONE') {
        if (type === 'likes') {
          return (b.sum?.likes || 0) - (a.sum?.likes || 0);
        } else if (type === 'plays') {
          return (b.sum?.plays || 0) - (a.sum?.plays || 0);
        } else {
          return dayjs(a.createdAt).isBefore(dayjs(b.createdAt))
            ? 1
            : dayjs(a.createdAt).isSame(dayjs(b.createdAt))
            ? 0
            : -1;
        }
      }

      return 0;
    });

    this.operatePlatforms = sorted;
  }

  // 创建运营平台
  async createOperatePlatform(params: OperatePlatformParams) {
    const res = await userServices.createOperatePlatform(params);
    return Promise.resolve(res);
  }

  // 进入到提交验证码页面,准备输入验证码
  async readyToGetCode(params: PlatformCodeParams) {
    const res = await userServices.readyToGetCode(params);
    return Promise.resolve(res);
  }

  // 发送/提交验证码 (code为-1时为重新发送, 传code时为提交)
  async submitPlatformCode(params: PlatformCodeParams) {
    const res = await userServices.submitPlatformCode(params);
    return Promise.resolve(res);
  }

  // 获取用户输入的验证码,用来判断用户有没有输入过
  async getUserPlatformCode(pid: number) {
    const res = await userServices.getUserPlatformCode(pid);
    return Promise.resolve(res);
  }

  // 认证错误信息模版
  async getAuthErrorReason() {
    const res = await userServices.getAuthErrorReason();
    return Promise.resolve(res);
  }

  tourRefs: any[] = [];

  saveTourRef(index: number, ref: any) {
    this.tourRefs[index] = ref;
  }
}
