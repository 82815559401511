import UserStore from './UserStore';
import CommonStore from './CommonStore';
import LanguageStore from './LanguageStore';
import ContentStore from './ContentStore';
import MaterialStore from './MaterialStore';
import WalletStore from './WalletStore';

const stores = {
  user: new UserStore(),
  common: new CommonStore(),
  language: new LanguageStore(),
  content: new ContentStore(),
  material: new MaterialStore(),
  wallet: new WalletStore(),
};

export default stores;
