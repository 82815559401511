import { GlobalToken } from 'antd';

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  // 背景色
  bgContainer: (token: GlobalToken) => {
    return { backgroundColor: token.colorBgContainer };
  },
  bgContainerDisabled: (token: GlobalToken) => {
    return { backgroundColor: token.colorBgContainerDisabled };
  },
  bgElevated: (token: GlobalToken) => {
    return { backgroundColor: token.colorBgElevated };
  },
  bgLayout: (token: GlobalToken) => {
    return { backgroundColor: token.colorBgLayout };
  },
  bgMask: (token: GlobalToken) => {
    return { backgroundColor: token.colorBgMask };
  },
  bgBase: (token: GlobalToken) => {
    return { backgroundColor: token.colorBgBase };
  },
  bgPrimary: (token: GlobalToken) => {
    return { backgroundColor: token.colorPrimaryBg };
  },
  bgSuccess: (token: GlobalToken) => {
    return { backgroundColor: token.colorSuccessBg };
  },
  bgError: (token: GlobalToken) => {
    return { backgroundColor: token.colorErrorBg };
  },
  bgWarning: (token: GlobalToken) => {
    return { backgroundColor: token.colorWarningBg };
  },
  bgFail: (token: GlobalToken) => {
    return { backgroundColor: (token as any).colorFailBg };
  },

  // 颜色
  colorPrimary: (token: GlobalToken) => {
    return { color: token.colorPrimary };
  },
  colorSuccess: (token: GlobalToken) => {
    return { color: token.colorSuccess };
  },
  colorError: (token: GlobalToken) => {
    return { color: token.colorError };
  },
  colorWarning: (token: GlobalToken) => {
    return { color: token.colorWarning };
  },
  colorFail: (token: GlobalToken) => {
    return { color: (token as any).colorFail };
  },
  colorLink: (token: GlobalToken) => {
    return { color: token.colorLink };
  },

  // text color
  textColor: (token: GlobalToken) => {
    return { color: token.colorText };
  },
  textSecondary: (token: GlobalToken) => {
    return { color: token.colorTextSecondary };
  },
  textBase: (token: GlobalToken) => {
    return { color: token.colorTextBase };
  },
  textLabel: (token: GlobalToken) => {
    return { color: token.colorTextLabel };
  },
  textPlaceholder: (token: GlobalToken) => {
    return { color: token.colorTextPlaceholder };
  },
  textTertiary: (token: GlobalToken) => {
    return { color: token.colorTextTertiary };
  },

  // border
  colorBorder: (token: GlobalToken) => {
    return { color: token.colorBorder };
  },
  colorBorderBg: (token: GlobalToken) => {
    return { color: token.colorBorderBg };
  },
  colorBorderSecondary: (token: GlobalToken) => {
    return { color: token.colorBorderSecondary };
  },
  border: (token: GlobalToken, lineWidth = 1, lineStyle = 'solid') => {
    return { border: `${lineWidth}px ${lineStyle} ${token.colorBorder}` };
  },
  borderBg: (token: GlobalToken, lineWidth = 1, lineStyle = 'solid') => {
    return { border: `${lineWidth}px ${lineStyle} ${token.colorBorderBg}` };
  },
  borderSecondary: (token: GlobalToken, lineWidth = 1, lineStyle = 'solid') => {
    return { color: `${lineWidth}px ${lineStyle} ${token.colorBorderBg}` };
  },
};
