// console.log('window.location.protocol', window.location.protocol);

const handleBaseUrl = (local?: string, protocol?: string) => {
  let baseUrl = '';
  let dataUrl = '';

  switch (local) {
    case 'www.adobaro.com': // 生产环境
    case 'v2.adobaro.com': // 生产环境
    case 'adobaro.com': // 生产环境
    case 'allen.adoba.tv': // 临时 生产环境
      // baseUrl = `${protocol}//api.adobaro.com`;
      baseUrl = `https://api.adobaro.com:9443`;
      dataUrl = `http://3.38.221.35:9080`;
      break;
    default:
      baseUrl = `${protocol}//18.163.16.71:9080`;
      dataUrl = `http://18.163.16.71:9080`;
      break;
  }

  return { baseUrl, dataUrl };
};

const CONFIG = {
  BASEURL: handleBaseUrl(window.location.host, window.location.protocol).baseUrl,
  DATAURL: handleBaseUrl(window.location.host, window.location.protocol).dataUrl,
};

export default CONFIG;
