import { makeAutoObservable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import commonServices from '@/services/commonServices';
import { ThemeModeType, PlatformType } from './types';
import StringUtils from '@/utils/StringUtils';

export default class CommonStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'CommonStore',
      properties: ['theme'],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  theme: ThemeModeType = 'dark';
  setTheme(mode: ThemeModeType) {
    this.theme = mode;
  }

  async getOssPolicy() {
    const res = await commonServices.getOssPolicy();
    return Promise.resolve(res);
  }

  async getAwsConfig() {
    const res = await commonServices.getAwsConfig();
    return Promise.resolve(res);
  }

  phoneCodeList = [
    { zh: '美国', name: 'country_USA', code: 1 },
    { zh: '加拿大', name: 'country_CAN', code: 1 },
    { zh: '韩国', name: 'country_KRW', code: 82 },
    { zh: '中国', name: 'country_CHN', code: 86 },
  ];

  getCountryCode(name: string) {
    const country = this.phoneCodeList.find((item) => item.name === name);
    return country ? country.code : '';
  }

  defaultPlatformList: PlatformType[] = [
    {
      id: 1,
      name: 'platform_hao_kan',
      icon: require('@/assets/platformIcon/haokan.png'),
      sms: require('@/assets/platformCode/baidu.png'),
    },
    {
      id: 2,
      name: 'platform_tencent',
      icon: require('@/assets/platformIcon/tencent.png'),
    },
    {
      id: 3,
      name: 'platform_iqiyi',
      icon: require('@/assets/platformIcon/iqiyi.png'),
      sms: require('@/assets/platformCode/iqiyi.png'),
    },
    {
      id: 4,
      minute: 5,
      name: 'platform_bilibili',
      icon: require('@/assets/platformIcon/bili.png'),
      sms: require('@/assets/platformCode/bili.png'),
    },
    {
      id: 5,
      minute: 5,
      name: 'platform_xi_gua',
      icon: require('@/assets/platformIcon/xigua.png'),
      sms: require('@/assets/platformCode/xigua.png'),
    },
    {
      id: 6,
      name: 'platform_wei_bo',
      icon: require('@/assets/platformIcon/weibo.jpg'),
      sms: require('@/assets/platformCode/weibo.png'),
    },
    {
      id: 7,
      name: 'platform_youtube',
      icon: require('@/assets/platformIcon/youtube.png'),
    },
    {
      id: 8,
      minute: 5,
      name: 'platform_dou_yin',
      icon: require('@/assets/platformIcon/douyin.png'),
      sms: require('@/assets/platformCode/douyin.png'),
    },
    {
      id: 9,
      minute: 3,
      name: 'platform_red_book',
      icon: require('@/assets/platformIcon/red_book.jpg'),
      sms: require('@/assets/platformCode/redbook.png'),
    },
    {
      id: 10,
      minute: 15,
      name: 'platform_kuai_shou',
      icon: require('@/assets/platformIcon/kwai.jpg'),
      sms: require('@/assets/platformCode/kwai.png'),
    },
    {
      id: 11,
      name: 'platform_cloud_music',
      icon: require('@/assets/platformIcon/net_music.png'),
    },
    {
      id: 12,
      name: 'platform_wechat_video',
      icon: require('@/assets/platformIcon/shipin.jpg'),
    },
    {
      id: 13,
      name: 'platform_mei_pai',
      icon: require('@/assets/platformIcon/meipai.jpg'),
    },
    {
      id: 14,
      name: 'platform_da_yu',
      icon: require('@/assets/platformIcon/dayu.jpg'),
      sms: require('@/assets/platformCode/dayu.png'),
    },
    {
      id: 15,
      minute: 15,
      name: 'platform_dian_tao',
      icon: require('@/assets/platformIcon/diantao.png'),
      sms: require('@/assets/platformCode/diantao.png'),
    },
    {
      id: 16,
      name: 'platform_qq_world',
      icon: require('@/assets/platformIcon/qqworld.jpg'),
      sms: require('@/assets/platformCode/qqworld.png'),
    },
    { id: 99, name: 'platform_youtube', icon: require('@/assets/platformIcon/youtube.png') },
    { id: 100, name: 'platform_tiktok', icon: require('@/assets/platformIcon/tiktok.png') },
    { id: 101, name: 'platform_instagram', icon: require('@/assets/platformIcon/instagram.png') },
    { id: 102, name: 'platform_twitter', icon: require('@/assets/platformIcon/twitter.png') },
  ];

  platformList: PlatformType[] = [];
  foreignPlatformsList: PlatformType[] = [];
  async getPlatforms() {
    const res = await commonServices.getPlatforms();
    this.savePlatforms(res.list);
    return Promise.resolve(res.list);
  }

  savePlatforms(list: PlatformType[]) {
    const platforms = list
      .filter((item) => item.id !== 8 && item.id !== 14 && item.id !== 15) // 暂时不支持
      .map((plat) => {
        const platform = this.defaultPlatformList.find((item) => item.id === plat.id);
        return {
          ...plat,
          ...platform,
          id: plat.id,
          name: platform?.name || plat.name,
          icon: platform?.icon || require('@/assets/platformIcon/empty.png'),
          isForeign: plat.isForeign || false,
        };
      });

    this.platformList = (platforms || []).filter((p) => p.isForeign === false);
    this.foreignPlatformsList = (platforms || []).filter((p) => p.isForeign);
  }

  getPlatformName(id?: number | string) {
    const platform = this.defaultPlatformList.find((p) => p.id === Number(id));
    return platform?.name || id;
  }

  getPlatformIcon(id?: number | string) {
    const platform = this.defaultPlatformList.find((p) => p.id === Number(id));
    return platform?.icon || require('@/assets/platformIcon/empty.png');
  }

  getPlatformMinute(id?: number | string) {
    const platform = this.defaultPlatformList.find((p) => p.id === Number(id));
    return platform?.minute || 10;
  }

  getPlatformSms(id?: number | string) {
    const platform = this.defaultPlatformList.find((p) => p.id === Number(id));
    return platform?.sms || require('@/assets/platformIcon/empty.png');
  }

  // 获取youtube 视频详情
  async getYoutubeVideo(params: any) {
    const res = await commonServices.getYoutubeVideo(params);
    return Promise.resolve(res);
  }

  // 友盟初始化: 集成应用的appKey
  uMengInit() {
    const { aplus_queue } = window as any;

    // 测试环境Key: 64649905ba6a5259c4536799
    // 生产环境Key: 64867872e31d6071ec4ebbb4
    const key =
      StringUtils.getEnvironment() === 'dev'
        ? '64649905ba6a5259c4536799'
        : '64867872e31d6071ec4ebbb4';

    aplus_queue.push({
      action: 'aplus.setMetaInfo',
      arguments: ['appKey', key],
    });
  }

  // 记录页面点击事件
  uMengRecord(eventCode: string, eventParams: any) {
    const { aplus_queue } = window as any;
    aplus_queue.push({
      action: 'aplus.record',
      arguments: [eventCode, 'CLK', eventParams],
    });
  }
}
